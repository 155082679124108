import { Dimensions } from 'react-native'
import { useState, useEffect } from 'react'


export default function ScreenSize() {

    const [screenInfo, setScreenInfo] = useState(Dimensions.get('window'))

    const ratio = (number) => {
        return (screenInfo.height / number) + (screenInfo.width / (number * 2.75))
        // return (screenInfo.height / number * 1.4) + (screenInfo.width / (number * 2.75))
        // return (screenInfo.height * '0.' + number) + (screenInfo.width * "0." + (number * 2.75))
    }

    useEffect(() => {

        const onChange = (result) => {
            setScreenInfo(result.screen)
        }

        Dimensions.addEventListener('change', onChange)

        return () => Dimensions.removeEventListener('change', onChange)

    }, [])

    return {
        ...screenInfo,
        isWide: screenInfo.width > screenInfo.height ? true : false,
        ratio
        // ratio: (screenInfo.height / number) + (screenInfo.width / (number * 2.75))
    }
}
