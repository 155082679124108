// // // Rename this file to "firebaseConfig.js" before use
// // // Replace all XXXXs with real Firebase API keys


// import firebase from "firebase";
// import "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";

// var firebaseConfig = {


//       // apiKey: "AIzaSyDxoGjiILSv0RzuI9detPxbkNsWYqMPLzQ",
//       // authDomain: "mommy-crochet-79db7.firebaseapp.com",
//       // projectId: "mommy-crochet-79db7",
//       // storageBucket: "mommy-crochet-79db7.appspot.com",
//       // messagingSenderId: "655838699913",
//       // appId: "1:655838699913:web:b14cc110e93314a8620158",
//       // measurementId: "G-X83457JC93"
//       apiKey: "AIzaSyCm9GS7rBPHpZ5YaaFFG7xa4Qx2UhGOrcA",
//       authDomain: "laptop-galaxy.firebaseapp.com",
//       projectId: "laptop-galaxy",
//       storageBucket: "laptop-galaxy.appspot.com",
//       messagingSenderId: "782056497009",
//       appId: "1:782056497009:web:01f9eecb9c58a9d42660d0",
//       measurementId: "G-C21F4XGEVN"


// };
// // Initialize Firebase
// if (!firebase.apps.length) {
//       firebase.initializeApp(firebaseConfig);
// }
// export default firebase;
// // // Rename this file to "firebaseConfig.js" before use
// // // Replace all XXXXs with real Firebase API keys


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { initializeApp } from 'firebase/app';

var firebaseConfig = {
      apiKey: "AIzaSyCm9GS7rBPHpZ5YaaFFG7xa4Qx2UhGOrcA",
      authDomain: "laptop-galaxy.firebaseapp.com",
      projectId: "laptop-galaxy",
      storageBucket: "laptop-galaxy.appspot.com",
      messagingSenderId: "782056497009",
      appId: "1:782056497009:web:01f9eecb9c58a9d42660d0",
      measurementId: "G-C21F4XGEVN"
};

if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
}



// Initialize Firebase
// initializeApp({
//       apiKey: "AIzaSyCm9GS7rBPHpZ5YaaFFG7xa4Qx2UhGOrcA",
//       authDomain: "laptop-galaxy.firebaseapp.com",
//       projectId: "laptop-galaxy",
//       storageBucket: "laptop-galaxy.appspot.com",
//       messagingSenderId: "782056497009",
//       appId: "1:782056497009:web:01f9eecb9c58a9d42660d0",
//       measurementId: "G-C21F4XGEVN"
// });


export const auth = firebase.auth()

export const loginWithEmail = (email, password) =>
      auth.signInWithEmailAndPassword(email, password);

export const registerWithEmail = (email, password) =>
      auth.createUserWithEmailAndPassword(email, password);

export const logout = () => auth.signOut();

export const passwordReset = email => auth.sendPasswordResetEmail(email);
export default firebase;