import { notification } from 'antd'
// notification.
export default function openNotificationWithIcon(type, message, description) {
    if (type === 'success') {
        notification[type]({
            message: message,
            description: description,
            duration: 0
        });
    } else {
        notification[type]({
            message: message,
            description: description,
            duration: 6
        })
    }

}













// import { notification } from 'antd'


// // const [api, contextHolder] = notification.useNotification();
// export default function openNotificationWithIcon(type, message, description) {
//     const [api, contextHolder] = notification.useNotification();
//     // console.log(contextHolder)


//     const openNotificationWith = () => {
//         if (type === 'error') {
//             api[type]({
//                 message: message,
//                 description: description.message,
//                 duration: 0
//             });
//         }

//         if (type === 'success') {
//             api[type]({
//                 message: message,
//                 description: description,
//                 duration: 0
//             });
//         }
//         // };

//         return (openNotificationWith())
//     }
// }
// // export  {openNotificationWithIcon , contextHolder}