import { View, Text, Modal, Pressable, TextInput, ScrollView, Image, Button, FlatList } from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import firebase from "../firebase/firebaseConfig";
import { StyleSheet } from 'react-native';
import ScreenSize from "../components/ScreenSize";
import filter from "lodash.filter";
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Switch, Checkbox } from "native-base";
import { InputNumber, Slider, Select, Radio } from 'antd'
import * as Animatable from 'react-native-animatable'

const ProductsList = (props) => {
    const [FilterModalVisible, setFilterModalVisible] = useState(false)
    const [items, setItems] = useState([])
    const [searchQuery, setsearchQuery] = useState("");
    const [searchAds, setsearchAds] = useState([]);
    const [value, setValue] = useState(0);
    const [usedSwitch, setUsedSwitch] = useState(true)
    const [newSwitch, setNewSwitch] = useState(true)
    const [categoryValue, setCategoryValue] = useState(props.route.params?.productType)
    const [sortValue, setSortValue] = useState('newlyListed')
    const [cityValue, setCityValue] = useState(null)
    const [availablityCheckox, setAvailablityCheckox] = useState(false)
    const styles = useStyles();
    const screenSize = ScreenSize();
    const scrollViewRef = useRef()
    const propsProductType = props.route.params?.productType
    const [inputValue, setInputValue] = useState([0, 100000]);
    const [animation, setAnimation] = useState('slideOutUp');
    const [animationPressable, setAnimationPressable] = useState('fadeIn');

    const filteredProduct = () => {
        let newProduct = searchAds

        if (!usedSwitch) {
            newProduct = newProduct.filter((i) => i.condition === 'new')
        }
        if (!newSwitch) {
            newProduct = newProduct.filter((i) => i.condition === 'used')
        }
        if (categoryValue !== 'all') {
            newProduct = newProduct.filter((i) => i.ProductType === categoryValue)
        }
        if (cityValue) {
            newProduct = newProduct.filter((i) => i.Address.toLowerCase().includes(cityValue.toLowerCase()))
            // console.log(cityValue.toLowerCase())
            // newProduct = newProduct.filter((i) => i.Address.substring(0, i.Address.indexOf(",")).toLowerCase() == cityValue.toLowerCase())

            // var a = 'how are you';
            // return a.indexOf('are') > -1;

            // var a = 'how are you';
            // return a.includes('are');  //true
        }
        if (availablityCheckox) {
            newProduct = newProduct.filter((i) => i.isAvailable === true)
        }

        if (sortValue == 'lowerPrice') {
            newProduct = newProduct.sort((a, b) => {
                return a.Price - b.Price
            })
        }
        if (sortValue == 'higherPrice') {
            newProduct = newProduct.sort((a, b) => {
                return b.Price - a.Price
            })
        }
        if (sortValue == 'newlyListed') {
            newProduct = newProduct.sort((a, b) => {
                return a.creation - b.creation
            })
        }

        const min = inputValue[0]
        const max = inputValue[1]
        if (inputValue) {
            newProduct = newProduct.filter((i) => i.Price >= min && i.Price <= max)
        }

        return newProduct
    }
    //     const filteredProduct = searchAds.filter((product) =>
    //         product.category.toLowerCase().includes(filter.toLowerCase()) &&
    //         (priceFilter === '' || product.price <= parseFloat(priceFilter))
    //   )
    // const [key, setKey] = useState("");
    // const [valuegaming, setValuegaming] = useState(0);
    // const [valueBusiness, setValueBusiness] = useState(0);
    // const [valueWorkstations, setValueWorkstations] = useState(0);
    // const [valueFollowing, setValueFollowing] = useState(0);

    useEffect(() => {
        // console.log('0.' + 13)
        setCategoryValue(props.route.params?.productType)
        const fetchData = async () => {
            const value = await AsyncStorage.getItem(propsProductType);
            if (value !== null) {
                setValue(value)
            }
        };
        fetchData()

        // if (propsProductType != 'All') {
        //     firebase
        //         .firestore()
        //         .collection("Ads")
        //         .where("ProductType", "==", propsProductType)
        //         .orderBy("creation", "desc")
        //         .onSnapshot((snapshot) => {
        //             setItems(snapshot.docs.map((doc) => doc.data()));
        //             setsearchAds(snapshot.docs.map((doc) => doc.data()))
        //         });

        // } else {
        firebase
            .firestore()
            .collection("Ads")
            .orderBy("creation", "desc")
            .onSnapshot((snapshot) => {
                setItems(snapshot.docs.map((doc) => doc.data()));
                setsearchAds(snapshot.docs.map((doc) => doc.data()));
            });
        // }
        // else if (propsProductType === 'followingAds') {
        //     alert('will be available soon!')
        // }
    }, [props.route.params?.productType]);// create new list with the filtered parameters like {filter} library

    const handleSearch = (query) => {
        setsearchQuery(query);
        const formattedQuery = query?.toLowerCase();
        const filteredData = filter(items, (user) => {
            return contains(user, formattedQuery);
        });
        setsearchAds(filteredData);
    };

    const contains = ({ ShortDescription, LongDescription, adOwner }, query) => {
        const { name } = adOwner;
        if (
            ShortDescription?.toLowerCase().includes(query) ||
            LongDescription?.toLowerCase().includes(query) ||
            name?.toLowerCase().includes(query)
        ) {
            return true;
        }
        return false;
    };

    const marks = {
        0: 0,
        50000: 50000,
        100000: 100000,
    }

    const formatter = (value) => `${value} EGP`;
    // const formatter = (value) => `${value}%`;

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onSearch = (value) => {
        // console.log('search:', value);
    };

    const AnimatedPressable = Animatable.createAnimatableComponent(Pressable)

    const toggleSheet = () => {
        setFilterModalVisible(!FilterModalVisible);
    };

    // const onChange = (newValue) => {
    //     setInputValue(newValue);
    // };

    // const onChangeSelect = (value) => {
    //     console.log(`selected ${value}`);
    // };

    return (
        <View style={{
            flex: 1,
            // alignSelf: 'center',
            // justifyContent: 'center',
            alignItems: 'center',
            overflow: FilterModalVisible ? 'hidden' : 'visible'   //////////////////////////
        }}>
            <View style={{
                marginHorizontal: screenSize.isWide ? '9.2%' : 5,
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
            }}>

                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginVertical: 3,
                    width: '100%'
                    // marginHorizontal: 10,
                }}>
                    <TouchableOpacity onPress={() => props.navigation.navigate('Home')}>
                        <Ionicons name="chevron-back-circle" size={screenSize.ratio(22)} color="grey" />
                    </TouchableOpacity>
                    {/* <TouchableOpacity onPress={() => setSortModalVisible(true)}>
                    <FontAwesome name="sort-alpha-asc" size={30} color="grey" />
                </TouchableOpacity> */}

                    <TouchableOpacity onPress={() => { setAnimation("slideOutUp"), setFilterModalVisible(true), setAnimationPressable('fadeIn') }}  >
                        <MaterialCommunityIcons name="filter-variant" size={screenSize.ratio(22)} color="black" />
                    </TouchableOpacity>

                    <TextInput
                        placeholder="Search"
                        clearButtonMode="always"
                        style={styles.textInputModalSearch}
                        autoCapitalize="none"
                        value={searchQuery}
                        onChangeText={(query) => {
                            handleSearch(query)
                        }} />
                </View>
                <ScrollView
                    style={{
                        width: '100%', marginTop: screenSize.isWide ? 13 : 4, alignSelf: 'center',
                        overflow: 'hidden'
                        // backgroundColor: '#3700ff'
                    }}
                    horizontal={false}
                    ref={scrollViewRef}
                    scrollEventThrottle={16}
                    onScroll={({ nativeEvent }) => {
                        AsyncStorage.setItem(propsProductType, nativeEvent.contentOffset.y);
                        setValue(nativeEvent.contentOffset.y)
                        // console.log(nativeEvent.contentOffset.y)
                        // console.log(propsProductType)
                        // y: nativeEvent.layoutMeasurement.height,
                        // () => scrollViewRef.current.scrollToEnd()}
                    }}>

                    <ScrollView
                        onLayout={() => scrollViewRef?.current?.scrollTo({ x: 0, y: value, animated: false })}
                        style={{
                            alignSelf: 'center',
                            overflow: 'hidden'
                            // backgroundColor: 'red',
                        }}
                    // numColumns={2}
                    // data={
                    //     filteredProduct()
                    //     // searchAds
                    //     // data={searchAds.filter(item => { return item.Price > parseInt(Price, 10) })
                    //     // .filter(hotel => { return hotel.Price > parseInt(Price, 10) })
                    // }
                    // renderItem={(data, index) => {
                    // }}
                    >
                        <View style={styles.AdsWraper}>
                            {filteredProduct().map((data, index) => {
                                return (
                                    <TouchableOpacity
                                        key={index}
                                        style={{
                                            width: screenSize.isWide
                                                ? screenSize.width / 6.293
                                                // ? screenSize.width / 6.252
                                                : screenSize.width / 2.1,
                                            height: screenSize.isWide
                                                ? screenSize.width / 5.0344
                                                : screenSize.width / 1.65,
                                            marginBottom: screenSize.ratio(35),
                                            // alignItems: 'center',
                                            // marginRight: 4,
                                            // justifyContent: 'space-between',
                                            // backgroundColor: '#8446',
                                        }}
                                        onPress={() =>
                                            props.navigation.navigate("ProductInfo", {
                                                productId: data.id,
                                            })
                                        }
                                    >
                                        <View
                                            style={{
                                                width: "100%",
                                                // backgroundColor: 'yellow'
                                                // height: '75%',
                                                // height: screenSize.isWide
                                                //     ? screenSize.width / 6.1
                                                //     : screenSize.width / 2.5,
                                                // marginBottom: 3,
                                            }}
                                        >
                                            <Image
                                                source={data.productImageList[0].downloadURL}
                                                style={{
                                                    width: undefined,
                                                    height: undefined,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    borderRadius: 10,
                                                    aspectRatio: 1,
                                                    resizeMode: "cover",
                                                }}
                                            ></Image>
                                        </View>

                                        <View style={{
                                            // backgroundColor: '#9b9b9b',
                                            // width: '100%',
                                            // height: '100%'
                                        }}>
                                            <View>
                                                <Text numberOfLines={2}
                                                    style={{
                                                        fontSize: screenSize.ratio(67),
                                                        color: "#000",
                                                        fontWeight: "400",
                                                        opacity: 0.8,
                                                    }}
                                                >
                                                    {data.ShortDescription}
                                                </Text>
                                            </View>

                                            <View
                                                style={{
                                                    flexDirection: "row",
                                                    right: 4,
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Text style={{ fontSize: 14, fontWeight: "700" }}>
                                                    {" "}
                                                    EGP{" "}
                                                </Text>
                                                <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                                    {" "}
                                                    {" " + data.Price}
                                                </Text>
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                    // <TouchableOpacity
                                    //     key={index}
                                    //     style={{
                                    //         width: screenSize.isWide
                                    //             ? screenSize.width / 4.4
                                    //             : screenSize.width / 2.1,
                                    //         marginHorizontal: 5,
                                    //         marginTop: 10,
                                    //     }}
                                    //     onPress={() => {
                                    //         props.navigation.navigate("ProductInfo", {
                                    //             productId: data.item.id,
                                    //         })
                                    //     }}>
                                    //     <View
                                    //         style={{
                                    //             width: "100%",
                                    //             height: screenSize.isWide
                                    //                 ? screenSize.width / 4.5
                                    //                 : screenSize.width / 2.5,
                                    //         }}>
                                    //         <Image
                                    //             source={data.item.photo}
                                    //             style={{
                                    //                 width: undefined,
                                    //                 height: undefined,
                                    //                 alignItems: "center",
                                    //                 justifyContent: "center",
                                    //                 borderRadius: 10,
                                    //                 aspectRatio: 1,
                                    //                 resizeMode: "cover",
                                    //             }}
                                    //         ></Image>
                                    //     </View>
                                    //     <View style={{ marginTop: 30, flex: 1 }}>
                                    //         <View>
                                    //             <Text
                                    //                 style={{
                                    //                     fontSize: 13,
                                    //                     color: "#000",
                                    //                     fontWeight: "400",
                                    //                     opacity: 0.8,
                                    //                 }}
                                    //             >
                                    //                 {data.item.ShortDescription?.length > 80
                                    //                     ? `${data.item.ShortDescription.substring(0, 80)}...`
                                    //                     : data.item.ShortDescription}
                                    //             </Text>
                                    //         </View>

                                    //         <View
                                    //             style={{
                                    //                 flexDirection: "row",
                                    //                 right: 4,
                                    //                 alignItems: "center",
                                    //             }}
                                    //         >
                                    //             <Text style={{ fontSize: 14, fontWeight: "700" }}>
                                    //                 {" "}
                                    //                 EGP{" "}
                                    //             </Text>
                                    //             <Text style={{ fontSize: 16, fontWeight: "500" }}>
                                    //                 {" "}
                                    //                 {" " + data.item.Price}
                                    //             </Text>
                                    //         </View>
                                    //     </View>
                                    // </TouchableOpacity>
                                )
                            })}
                        </View>
                    </ScrollView>
                </ScrollView>
            </View>



            {FilterModalVisible && (
                <>
                    <AnimatedPressable
                        animation={animationPressable}
                        duraton="600"
                        style={styles.backdrop}
                        onPress={() => {
                            setAnimation('slideOutDown')
                            setAnimationPressable('fadeOut')
                            setTimeout(toggleSheet, 650)
                        }} />

                    <Animatable.View style={styles.sheet} animation={animation} duraton="600" easing='ease-out-back'>
                        {/* <Text style={styles.modalText}>Set Your Filters</Text> */}
                        <Text style={{
                            fontSize: 17, fontWeight: '500',
                            // marginTop: 15,
                            textDecorationLine: 'underline'
                        }}>Price Range :</Text>

                        <Slider
                            style={{ width: '92%', alignSelf: 'center' }}
                            range
                            defaultValue={[0, 100000]}
                            min={0}
                            max={100000}
                            marks={marks}
                            onChange={(newValue) => { setInputValue(newValue) }}
                            ticks
                            value={inputValue}
                            tooltip={{ formatter }}
                        />
                        <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text>From:</Text>
                                <InputNumber
                                    min={0}
                                    max={99999}
                                    style={{ margin: '0 16px' }}
                                    value={typeof inputValue[0] === 'number' ? inputValue[0] : 0}
                                    onChange={(value) => {
                                        setInputValue(prevState => [Number(value), Number(prevState[1])])
                                    }} />
                            </View>

                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text>To:</Text>
                                <InputNumber
                                    min={1}
                                    max={100000}
                                    style={{ margin: '0 16px' }}
                                    value={typeof inputValue[1] === 'number' ? inputValue[1] : 0}
                                    onChange={(value) => {
                                        setInputValue(prevState => [Number(prevState[0]), Number(value)])
                                    }}
                                />
                            </View>
                        </View>

                        <Text style={{ fontSize: 17, fontWeight: '500', marginTop: 15, textDecorationLine: 'underline' }}>Condition :</Text>
                        <View style={{ marginTop: 7 }}>
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                                <Text style={{ fontSize: 15 }}>New Laptops</Text>
                                <Switch
                                    disabled={usedSwitch == false}
                                    value={newSwitch}
                                    offTrackColor="orange.100" onTrackColor="orange.200" onThumbColor="orange.500" offThumbColor="orange.50"
                                    onToggle={() => {
                                        setNewSwitch(prevState => !prevState);
                                    }} />
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 4 }}>
                                <Text style={{ fontSize: 15 }}>Used Laptops</Text>
                                <Switch
                                    disabled={newSwitch == false}
                                    value={usedSwitch}
                                    offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50"
                                    onToggle={() => {
                                        setUsedSwitch(prevstate => !prevstate)
                                    }} />
                            </View>
                        </View>


                        <Text style={{ fontSize: 17, fontWeight: '500', marginTop: 15, textDecorationLine: 'underline' }}>Category :</Text>
                        <Radio.Group size='large'
                            style={{ marginTop: 7, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}
                            buttonStyle="solid"
                            onChange={newValue => { setCategoryValue(newValue.target.value) }}
                            // onChange={newValue => { console.log(newValue.target.value); setCategoryValue(newValue.target.value) }}
                            value={categoryValue}
                        >
                            <Radio.Button
                                style={{
                                    width: '13%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                    // paddingInline: -screenSize.width / 10
                                }}
                                value="all">All</Radio.Button>
                            <Radio.Button
                                style={{
                                    width: '25%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                    // paddingInline: -screenSize.width / 10
                                }}
                                value="gamingLaptop">Gaming</Radio.Button>
                            <Radio.Button
                                style={{
                                    width: '35%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                    // paddingInline: -screenSize.width / 10
                                }}
                                value="workstation">Workstations</Radio.Button>
                            <Radio.Button
                                style={{
                                    width: '27%',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                    // paddingInline: -screenSize.width / 10
                                }}
                                value="businessLaptop">Business</Radio.Button>
                        </Radio.Group>


                        <Text style={{ fontSize: 17, fontWeight: '500', marginTop: 15, textDecorationLine: 'underline' }}>Sort :</Text>
                        <Radio.Group
                            value={sortValue}
                            size='large'
                            onChange={(newValue) => { setSortValue(newValue.target.value) }}
                            // onChange={(newValue) => { console.log(newValue.target.value); setSortValue(newValue.target.value) }}
                            style={{
                                marginTop: 7,
                                // alignSelf: 'center',
                                // justifyContent: 'space-between',
                                // paddingInline: 1,
                                // width: '100%'
                                // flex: 1,
                                // flexWrap: 'wrap',
                                // flexDirection: 'row'
                                // backgroundColor: 'rebeccapurple',
                                // display: 'flex',
                                // flexDirection: 'row',
                                // flexWrap: 'wrap',
                                // alignItems: 'center'
                            }}
                        >
                            <Radio
                                // style={{ flexBasis: '2%' }}
                                value='newlyListed'>Newly Listed</Radio>
                            <Radio
                                // style={{ flexBasis: '2%' }}
                                // style={{ marginInline: - 20 }}
                                value='higherPrice'>Higher Price</Radio>
                            <Radio
                                // style={{ flexBasis: '2%' }}
                                value='lowerPrice'>Lower Price</Radio>
                        </Radio.Group>


                        <Text style={{ fontSize: 17, fontWeight: '500', marginTop: 15, textDecorationLine: 'underline' }}>Availablity :</Text>
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 4 }}>
                            <Text style={{ fontSize: 15 }}>Available only</Text>

                            <Checkbox
                                onChange={() => {
                                    setAvailablityCheckox(oldState => !oldState)
                                }}
                            // style={{}}
                            // onClick={() => {
                            //     setAvailablityCheckox(oldState => !oldState)
                            // }}
                            >
                            </Checkbox>
                        </View>


                        <Text style={{ fontSize: 17, fontWeight: '500', marginTop: 15, textDecorationLine: 'underline' }}>Location :</Text>
                        <Select
                            style={{ marginTop: 7 }}
                            value={cityValue}
                            showSearch
                            placeholder="Select a city"
                            optionFilterProp="children"
                            onChange={(newValue) => { setCityValue(newValue) }}
                            // onChange={(newValue) => { console.log(newValue); setCityValue(newValue) }}
                            onSearch={onSearch}
                            filterOption={filterOption}
                            options={states}
                        />

                        <Pressable
                            // style={styles.button}
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => {
                                setAnimation('slideOutDown')
                                setAnimationPressable('fadeOut')
                                setTimeout(toggleSheet, 600)
                            }} >
                            <Text style={styles.textStyle}>Done</Text>
                        </Pressable>
                    </Animatable.View>
                </>
            )}
        </View>
    )
}

export default ProductsList

function useStyles() {
    return StyleSheet.create({
        textInput: {
            fontSize: ScreenSize().isWide
                ? ScreenSize().width / 50
                : ScreenSize().width / 19,
            paddingLeft: 10,
            borderWidth: 2,
            borderRadius: 8,
            borderColor: "#ccc",
            paddingVertical: 10,
            marginTop: 6,
            paddingHorizontal: 20,
            shadowOffset: 50,
            shadowOpacity: 30,
            elevation: 30,
        },
        textInputModalSearch: {
            width: '90%',
            fontSize: ScreenSize().isWide
                ? ScreenSize().width / 50
                : ScreenSize().width / 19,
            borderWidth: 2,
            borderRadius: 8,
            borderColor: "#ccc",
            paddingVertical: 10,
            shadowOffset: 50,
            shadowOpacity: 30,
            elevation: 30,
            // paddingHorizontal: '60%',
            // paddingHorizontal: ScreenSize().isWide
            //     ? ScreenSize().width / 50
            //     : ScreenSize().width / 14,
            // paddingLeft: 10,
            // marginTop: 6,
        },
        text: {
            fontSize: 24,
            fontStyle: "italic",
            paddingLeft: 13,
        },
        textShortDes: {
            fontSize: 12,
            fontWeight: "600",
            // backgroundColor: '#eeeeee'
            // height: '30%',
            // width: 100,
            // marginLeft: 7,
            // top: 2
        },
        textLongDes: {
            fontSize: 10,
            fontWeight: "400",
            color: "#000",
            opacity: 0.9,
            width: 120,
            // maxHeight: '30%',
            // height: '100%',
            // backgroundColor: 'grey',
            // bottom: 2,
            // marginLeft: 7,
            // marginTop: 1,
        },
        image: {
            height: 70,
            width: 70,
            borderRadius: 10,
        },
        itemContainer: {
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 10,
            marginTop: 10,
            // top: 0
        },
        AdsWraper: {
            // display: 'flex',
            // flexDirection: 'row',
            // flexWrap: 'wrap',
            // alignItems: 'center',
            // justifyContent: 'space-around',

            // display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            alignSelf: 'center',
            alignContent: 'center',
            justifyContent: 'space-evenly',
            // justifyContent: 'space-between',
            // backgroundColor: 'rgb(204, 255, 1)'
        },
        centeredView: {
            flex: 1,
            // justifyContent: 'center',
            // alignItems: 'center',
            // marginTop: 50,
        },
        modalView: {
            flex: 1,
            // margin: 20,
            // width: '85%',
            // height: '85%',
            backgroundColor: '#d2c2ef',
            borderRadius: 20,
            padding: 15,
            // alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
        },
        button: {
            borderRadius: 20,
            elevation: 2,
            padding: 10,
            alignSelf: 'center',
            paddingHorizontal: ScreenSize().isWide ? ScreenSize().width / 8 : ScreenSize().width / 2.5,
            marginTop: 10,
            marginBottom: 25
            // position: 'absolute',
            // bottom: 45,
            // height: 40
        },
        buttonOpen: {
            backgroundColor: '#F194FF',
        },
        buttonClose: {
            backgroundColor: '#2196F3',
        },
        textStyle: {
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        modalText: {
            marginBottom: 15,
            textAlign: 'center',
            fontSize: 20,
            fontWeight: '800',
            // borderBottomWidth: 1
        },
        input: {
            backgroundColor: "white",
            height: 50,
            width: 200,
            borderWidth: 2,
            borderRadius: 20,
            borderColor: "black",
            paddingLeft: 15,
            fontSize: 20,
            margin: 10
        },
        separator: {
            paddingTop: 20
        },
        sheet: {
            backgroundColor: "white",
            height: '100%',
            width: ScreenSize().isWide ? '35%' : "100%",
            position: "absolute",
            bottom: -ScreenSize().height / 6,
            borderRadius: 20,
            zIndex: 1,
            paddingHorizontal: 16,
            paddingTop: 14,
            flex: 1,
            overflow: 'hidden'
            // padding: 16,
            // height: ScreenSize().height / 1.5,
            // marginHorizontal: 1000,
            // bottom: -20 * 7,
            // borderTopRightRadius: 20,
            // borderTopLeftRadius: 20,
        },
        // sheet: {
        //     backgroundColor: "white",
        //     padding: 16,
        //     height: ScreenSize().height / 1.2,
        //     width: "100%",
        //     position: "absolute",
        //     bottom: -ScreenSize().height / 8,
        //     borderRadius: 20,
        //     zIndex: 1,
        //     // marginHorizontal: 1000,
        //     // bottom: -20 * 7,
        //     // borderTopRightRadius: 20,
        //     // borderTopLeftRadius: 20,
        // },
        backdrop: {
            ...StyleSheet.absoluteFillObject,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 1,
        },
    });
}
const states = [
    { "value": 'Kafr el-Sheikh', "label": "Kafr el-Sheikh", "iso2": "KFS" },
    { "value": "Cairo", "label": "Cairo", "iso2": "C" },
    { "value": "Damietta", "label": "Damietta", "iso2": "DT" },
    { "value": "Aswan", "label": "Aswan", "iso2": "ASN" },
    { "value": 'Sohag', "label": "Sohag", "iso2": "SHG" },
    { "value": 'North Sinai', "label": "North Sinai", "iso2": "SIN" },
    { "value": 'Monufia', "label": "Monufia", "iso2": "MNF" },
    { "value": 'Port Said', "label": "Port Said", "iso2": "PTS" },
    { "value": 'Beni Suef', "label": "Beni Suef", "iso2": "BNS" },
    { "value": 'Matrouh', "label": "Matrouh", "iso2": "MT" },
    { "value": 'Qalyubia', "label": "Qalyubia", "iso2": "KB" },
    { "value": 'Suez', "label": "Suez", "iso2": "SUZ" },
    { "value": 'Gharbia', "label": "Gharbia", "iso2": "GH" },
    { "value": 'Alexandria', "label": "Alexandria", "iso2": "ALX" },
    { "value": 'Asyut', "label": "Asyut", "iso2": "AST" },
    { "value": "South Sinai", "label": "South Sinai", "iso2": "JS" },
    { "value": 'Faiyum', "label": "Faiyum", "iso2": "FYM" },
    { "value": "Giza", "label": "Giza", "iso2": "GZ" },
    { "value": "Red Sea", "label": "Red Sea", "iso2": "BA" },
    { "value": "Beheira", "label": "Beheira", "iso2": "BH" },
    { "value": "Luxor", "label": "Luxor", "iso2": "LX" },
    { "value": "Minya", "label": "Minya", "iso2": "MN" },
    { "value": "Ismailia", "label": "Ismailia", "iso2": "IS" },
    { "value": "Dakahlia", "label": "Dakahlia", "iso2": "DK" },
    { "value": "New Valley", "label": "New Valley", "iso2": "WAD" },
    { "value": "Qena", "label": "Qena", "iso2": "KN" },
    { "value": "Sharqia", "label": "Sharqia", "iso2": "SHR" }
]