import ScreenSize from '../components/ScreenSize';
import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, Text, View, Image } from 'react-native';
import * as Yup from 'yup';

import { COLOURS } from '../components/database/Database';
import SafeView from '../components/SafeView';
import Form from '../components/Forms/Form';
import FormField from '../components/Forms/FormField';
import FormButton from '../components/Forms/FormButton';
import IconButton from '../components/IconButton';
import { loginWithEmail } from '../firebase/firebaseConfig';
import FormErrorMessage from '../components/Forms/FormErrorMessage';
import firebase from '../firebase/firebaseConfig';
import { getAuth } from 'firebase/auth';

// import useStatusBar from '../hooks/useStatusBar';

const validationSchema = Yup.object().shape({
      email: Yup.string()
            .required('Please enter a registered email')
            .email()
            .label('Email'),
      password: Yup.string()
            .required()
            .min(6, 'Password must have at least 6 characters')
            .label('Password')
});

export default function LoginScreen({ navigation }) {
      const styles = useStyles()
      const screenSize = ScreenSize();

      // useStatusBar('dark-content');

      const [passwordVisibility, setPasswordVisibility] = useState(true);
      const [rightIcon, setRightIcon] = useState('eye');
      const [loginError, setLoginError] = useState('');

      function handlePasswordVisibility() {
            if (rightIcon === 'eye') {
                  setRightIcon('eye-off');
                  setPasswordVisibility(!passwordVisibility);
            } else if (rightIcon === 'eye-off') {
                  setRightIcon('eye');
                  setPasswordVisibility(!passwordVisibility);
            }
      }

      async function handleOnLogin(values) {
            const { email, password } = values;

            try {
                  await loginWithEmail(email, password);
                  firebase
                        .firestore()
                        .collection('Users')
                        .doc(getAuth()?.currentUser?.uid)
                        .update({ lastSignInTime: firebase.firestore.FieldValue.serverTimestamp(), })


                  firebase
                        .firestore()
                        .collection('activityLog')
                        .doc(getAuth()?.currentUser?.uid)
                        .collection('activityLog')
                        .add({
                              signIn: firebase.firestore.FieldValue.serverTimestamp(),
                        }).then(() => navigation.navigate('Home'))

            } catch (error) {
                  setLoginError(error.message);
                  console.log(error.message);
            }
      }

      return (
            <SafeView style={{
                  // flex: 1,
                  padding: 15,
                  marginHorizontal: screenSize.isWide ? screenSize.width / 2 : 0,
                  alignSelf: 'center',
                  width: screenSize.isWide ? screenSize.ratio(3) : '100%',
            }}>
                  {/* <SafeView style={styles.container}> */}
                  <Image
                        style={{
                              height: screenSize.ratio(3.2),
                              width: screenSize.ratio(3.2),
                              alignSelf: 'center',
                              // backgroundColor: 'red'
                        }}
                        source={require('../assets/welcome back .png')} />
                  {/* source={{ uri: 'https://www.google.com/imgres?q=png%20vector%20icon%20login%20welcome%20back&imgurl=https%3A%2F%2Fpng.pngtree.com%2Fpng-vector%2F20220123%2Fourmid%2Fpngtree-welcome-back-banner-png-image_226203.png&imgrefurl=https%3A%2F%2Fpngtree.com%2Fso%2Fwelcome-back&docid=t7BFjjBnb6Gs-M&tbnid=2iHBkGr2On9KOM&vet=12ahUKEwjutNyoqpqGAxWIRvEDHaG_AfAQM3oECF4QAA..i&w=360&h=360&hcb=2&ved=2ahUKEwjutNyoqpqGAxWIRvEDHaG_AfAQM3oECF4QAA' }} /> */}
                  <Form
                        initialValues={{ email: '', password: '' }}
                        validationSchema={validationSchema}
                        onSubmit={values => handleOnLogin(values)}
                  >
                        <FormField
                              name="email"
                              leftIcon="email"
                              placeholder="Email"
                              autoCapitalize="none"
                              keyboardType="email-address"
                              textContentType="emailAddress"
                              autoFocus={true}
                        />
                        <FormField
                              name="password"
                              leftIcon="lock"
                              placeholder="Password"
                              autoCapitalize="none"
                              autoCorrect={false}
                              secureTextEntry={passwordVisibility}
                              textContentType="password"
                              rightIcon={rightIcon}
                              handlePasswordVisibility={handlePasswordVisibility}
                        />
                        <FormButton title={'Login'} />
                        {<FormErrorMessage error={loginError} visible={true} />}
                  </Form>
                  <View style={styles.footerButtonContainer}>
                        <TouchableOpacity onPress={() => navigation.navigate('ForgotPassword')}>
                              <Text style={styles.forgotPasswordButtonText}>Forgot Password ?</Text>
                        </TouchableOpacity>
                  </View>
                  <IconButton
                        style={styles.backButton}
                        iconName="keyboard-backspace"
                        color="#000000"
                        size={30}
                        onPress={() => navigation.goBack() ? navigation.goBack() : navigation.navigate('Home')}
                  />
            </SafeView>
      );
}

function useStyles() {
      return StyleSheet.create({
            container: {
                  flex: 1,
                  padding: 15,
                  marginHorizontal: ScreenSize().isWide ? ScreenSize().width / 2 : 0,
                  alignSelf: 'center',
                  width: ScreenSize().isWide ? ScreenSize().ratio(3) : '100%',
                  // width: '100%',
                  // paddingVertical: 15,
                  // backgroundColor: COLOURS.backgroundMedium,
                  // alignItems: 'center',
                  // justifyContent: 'center',
                  // alignContent: 'center',
            },
            footerButtonContainer: {
                  marginVertical: 15,
                  justifyContent: 'center',
                  alignItems: 'center'
            },
            forgotPasswordButtonText: {
                  color: COLOURS.backgroundDark,
                  fontSize: 18,
                  fontWeight: '600'
            },
            backButton: {
                  justifyContent: 'center',
                  alignItems: 'center'
            }
      })
}
