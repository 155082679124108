import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";
// import { launchCameraAsync, useCameraPermissions, PermissionStatus } from 'expo-image-picker'

export default function ChildImage(props) {
    // const [loading, setloading] = useState(false);

    const pickImage = async () => {
        // setloading(true);
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            selectionLimit: 12,
            allowsMultipleSelection: true,
            orderedSelection: true,
            quality: 1,
            // allowsEditing: true,
            // aspect: [4, 3],
            // allowsMultipleSelection: goingBackScreen === 'EditProfile' ? false : true,
            // allowsEditing: goingBackScreen === 'EditProfile' ? true : false
            // base64: true,
            // presentationStyle: true,
        });
        // if (result.assets && result.assets.length > 0) {
        if (!result.canceled) {
            imageCallback(result.assets);
            // console.log(result.selected)
        }

        else {
            alert('You did not select any image.');
            props('canceled')
            console.log('cancelled')
        }

    };

    async function imageCallback(photos) {
        // setloading(true);
        const cPhotos = [];
        for (let photo of photos) {
            const pPhoto = await _processImageAsync(photo.uri);
            cPhotos.push(
                {
                    uri: pPhoto.uri,
                    fileSize: pPhoto.uri.length / 1365 + ' KB (after compress)',
                    // type: "image/jpg",
                    // fileName: pPhoto.uri.substring(pPhoto.uri.lastIndexOf('/') + 1, pPhoto.uri.length),
                    // fileName: pPhoto.uri.split("/").slice(-1)[0],
                }
            );
        }
        props(cPhotos.map((image, index) => {
            return { ...image, orderNumber: index + 1 }
        }))
        // setloading(false);
        // props.onPicsPicked(cPhotos)
    }

    async function _processImageAsync(uri) {
        const fileSize = uri.length / 1365
        if (fileSize < 1000) {
            const file = await ImageManipulator.manipulateAsync(uri, [], {
                compress: 1,
                // compress: fileSize < 3333 ? 0.4 : 0.3,
                // format: ImageManipulator.SaveFormat.JPEG,
            });
            return file;
        } else if (fileSize > 1000 && fileSize < 3000) {
            const file = await ImageManipulator.manipulateAsync(uri, [], { compress: 0.5, });
            return file;
        } else if (fileSize > 3000 && fileSize < 5000) {
            const file = await ImageManipulator.manipulateAsync(uri, [], { compress: 0.3, });
            return file;
        } else if (fileSize > 5000) {
            const file = await ImageManipulator.manipulateAsync(uri, [], { compress: 0.2, });
            return file;
        }
    }

    return (pickImage());
}

//  the same way you implemented the cover image badge... make it numbered pics whenever tapped and give every pic a number when upload to firestore and view them the same order
//  the same way you implemented the cover image badge... make it numbered pics whenever tapped and give every pic a number when upload to firestore and view them the same order
//  the same way you implemented the cover image badge... make it numbered pics whenever tapped and give every pic a number when upload to firestore and view them the same order

///////////////////////////////////////////////////////// IDENTICAL RESULT !!!   ////////////////////////////////////////////////////////
// console.log(result.assets[0].uri.trim().length)
// console.log(result.assets[0].uri.length * result.assets[0].width / 2000 )
///////////////////////////////////////////////////////// IDENTICAL RESULT !!!   ////////////////////////////////////////////////////////
// console.log(result.assets[0].fileSize)
// console.log(result.assets[0].fileName)