import React, { useEffect, useState } from "react";
import firebase from "../firebase/firebaseConfig";
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ScrollView,
} from "react-native";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ScreenSize from "../components/ScreenSize";
import { LinearGradient } from "expo-linear-gradient";
import { Button } from "native-base";
import { doc, updateDoc } from "firebase/firestore";

export default function ChatsScreen(props) {
  const screenSize = ScreenSize();
  const [chats, setChats] = useState([]);
  const [isUser, setisUser] = useState(null);
  // const [currentuser, setcurrentUser] = useState([]);
  // const combinedId = chats[1]?.userInfo?.uid > firebase.auth()?.currentUser?.uid ? chats[1]?.userInfo?.uid + firebase.auth()?.currentUser?.uid : firebase.auth()?.currentUser?.uid + chats[1]?.userInfo?.uid

  // async function fetchChats() {
  // const res = await getDoc(doc(firebase.firestore(), "userChats", firebase.auth()?.currentUser?.uid));

  // if (res.exists && JSON.stringify(res.data()) !== JSON.stringify({})) {
  //   setChats(res.data());
  //   // console.log(res.data())
  //   // console.log(JSON.stringify(res.data()))
  //   // console.log(res.data() == {})
  //   // console.log(JSON.stringify(res.data()) == JSON.stringify({}))
  //   // console.log({})
  // } else { console.log('no data') }


  // }

  useEffect(() => {
    onAuthStateChanged(getAuth(), (data) => {
      if (data) {
        setisUser(data);

        firebase
          .firestore()
          .collection("userChats")
          .doc(firebase.auth()?.currentUser?.uid)
          .onSnapshot((snapshot) => {
            // if (snapshot.data()) {
            if (JSON.stringify(snapshot.data()) !== JSON.stringify({})) {
              setChats(snapshot.data());
              // console.log(snapshot.data())
              // console.log(snapshot.data()[0])
              // console.log(snapshot.data()[1])
            } else {
              // setChats([]);
              console.log('no data')
            }
          });
        // fetchChats()

      } else {
        setisUser(null);
      }
    });
  }, []);


  if (isUser && chats.length != 0) {
    return (
      <View style={{ flex: 1 }}>
        {/* <ImageBackground
          style={{ flex: 1 }}
          source={{
            uri: "https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png",
          }}
        > */}
        <LinearGradient style={{ flex: 1 }} colors={['#DAE2F8', '#DAE2F8', '#D6A4A4']}>

          <ScrollView style={{ flex: 1, paddingHorizontal: screenSize.isWide ? screenSize.width / 2.8 : 0 }}>
            {Object.entries(chats)
              ?.sort((a, b) => b[1]?.date - a[1]?.date)
              .map((chat) => (
                <TouchableOpacity
                  key={chat[0]}
                  style={{
                    backgroundColor: '#fef0f3',
                    justifyContent: 'center',
                    borderRadius: 12,
                    marginTop: 6,
                    marginHorizontal: 5,
                    paddingHorizontal: 4,
                    paddingVertical: 9,
                    paddingRight: 7,
                    elevation: 5,
                    zIndex: 1,
                    shadowColor: '#a9a9a9',
                    shadowOffset: { width: 2, height: 3 },
                    shadowOpacity: 1,
                    shadowRadius: 10,
                  }}
                  onPress={() => {
                    const combinedId =
                      isUser?.uid > chat[1].userInfo?.uid
                        ? isUser?.uid + chat[1].userInfo?.uid
                        : chat[1].userInfo?.uid + isUser?.uid

                    updateDoc(doc(firebase.firestore(), "userChats", isUser?.uid), {
                      // updateDoc(doc(firebase.firestore(), "userChats", chat[1].userInfo?.uid), {
                      [combinedId + ".unreadMessagesNumber"]: 0,
                    });

                    props.navigation.navigate("Chat", {
                      userID: chat[1].userInfo?.uid,
                      // userBPhotoURL: chat[1].userInfo?.photoURL
                      // currentUser: currentuser,
                      // productData: chat[1].userInfo.adOwner?.uid,
                      // combinedId:
                      //   currentuser?.uid > chat[1].userInfo?.uid
                      //     ? currentuser?.uid + chat[1].userInfo?.uid
                      //     : chat[1].userInfo?.uid + currentuser?.uid,
                    })
                  }
                  }
                >
                  {/* {console.log(chat[1]?.lastMessage?.text)} */}
                  <View style={{ flexDirection: "row", flex: 1 }}>
                    <Image
                      source={{ uri: chat[1].userInfo?.photoURL }}
                      style={{
                        width: screenSize.height / 11,
                        height: screenSize.height / 11,
                        borderRadius: 50,
                        elevation: 5,
                        zIndex: 1,
                        shadowColor: '#a9a9a9',
                        shadowOffset: { width: 2, height: 3 },
                        shadowOpacity: 1,
                        shadowRadius: 10,
                      }}
                    />

                    <View style={{ justifyContent: "center", marginLeft: 7, flex: 1 }}>
                      <Text style={{ fontSize: 18, fontWeight: "bold", color: '#363636' }}>
                        {chat[1].userInfo?.displayName}
                      </Text>

                      <View style={{ flexDirection: "row", justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text numberOfLines={1} style={{ fontSize: 18, fontWeight: "400", color: "#444444", width: '95%' }}>
                          {chat[1].lastMessage}
                        </Text>

                        {chat[1].unreadMessagesNumber && chat[1].unreadMessagesNumber != 0 ?
                          <View style={{ borderRadius: 50, backgroundColor: '#e7bbbb', height: 35, width: 35, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: 18, fontWeight: "500", color: "#2b6870", }}>
                              {/* 2 */}
                              {chat[1].unreadMessagesNumber}
                            </Text>
                          </View> : null}

                      </View>
                    </View>


                    <View style={{
                      right: 2,
                      position: "absolute"
                    }}>
                      <Text style={{ color: '#2b6870' }}>
                        {chat[1].date && chat[1].date.toDate().toDateString()}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              ))}
          </ScrollView>
        </LinearGradient>
      </View>
    );
  }

  if (!isUser) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
        <Image source={require('../assets/noData.jpg')} style={{
          height: screenSize.ratio(4), width: screenSize.ratio(2.1), zIndex: 1, alignSelf: 'center', resizeMode: 'contain'
          // borderRadius: 30
        }} />
        <Text style={{ fontSize: screenSize.ratio(40), fontWeight: '700', fontFamily: 'Avigea' }}>You Have To Login First</Text>
        <Button rounded='xs' width={screenSize.ratio(3.5)} marginTop={50} onPress={() => props.navigation.navigate('MyAccount')}>Login</Button>
      </View>
    )
  }

  if (isUser && chats.length === 0) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
        <Image source={require('../assets/noChats.png')} style={{
          height: screenSize.ratio(4), width: screenSize.ratio(2.1), zIndex: 1, alignSelf: 'center', resizeMode: 'contain'
          // borderRadius: 30
        }} />
        <Text style={{ fontSize: screenSize.ratio(40), fontWeight: '700', fontFamily: 'Avigea' }}>You Don't Have Any Chats Yet</Text>
      </View>
    )
  }

}
