import React, { useState, useEffect } from 'react';
import * as WebBrowser from 'expo-web-browser';
// import { ResponseType } from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
// import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithCredential, onAuthStateChanged } from 'firebase/auth';
import { Button, TouchableOpacity, Image, ActivityIndicator } from 'react-native';
import firebase, { auth } from '../firebase/firebaseConfig'
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  Timestamp,
  arrayUnion,
  onSnapshot
} from "firebase/firestore";
import { Text } from 'react-native';
WebBrowser.maybeCompleteAuthSession();

export default function SigninWithGoogle({ navigation }) {
  const [isUser, setisUser] = useState([])
  const [loading, setloading] = useState(false)

  const [request, response, promptAsync] = Google.useIdTokenAuthRequest(
    {
      clientId: '782056497009-d41c9n9efbu9kn2nq2h7r9kr8g31ddfj.apps.googleusercontent.com',
      androidClientId: '782056497009-tq5oubkf7cj6scnel0emi10i4ebkep3p.apps.googleusercontent.com'
    },
  );

  // React.useEffect(() => {
  //   if (response?.type === 'success') {
  //     setloading(true)
  //     const { id_token } = response.params;
  //     const auth = getAuth();
  //     const credential = GoogleAuthProvider.credential(id_token);
  //     signInWithCredential(auth, credential)
  //     setisUser(auth)
  //   }
  //   setloading(false)
  // }, [response]);

  useEffect(() => {
    if (response?.type === 'success') {
      setloading(true)
      const { id_token } = response.params;
      const auth = getAuth()
      const credential = GoogleAuthProvider.credential(id_token);
      signInWithCredential(auth, credential).then((googleUserData) => {
        // console.log(googleUserData.user)
        // console.log(googleUserData)
        if (firebase.auth()?.currentUser?.metadata?.creationTime === firebase.auth()?.currentUser?.metadata?.lastSignInTime) {

          firebase
            .firestore()
            .collection('Users')
            .doc(getAuth().currentUser.uid)
            // .doc(firebase.auth().currentUser.uid ? firebase.auth().currentUser.uid : getAuth().currentUser.uid)
            .set({
              displayName: googleUserData.user.displayName,
              email: googleUserData.user.providerData[0].email === null ? googleUserData.user.email : googleUserData.user.providerData[0].email,
              uid: googleUserData.user.uid,
              photoURL: googleUserData.user.photoURL,
              phoneNumber: googleUserData.user.phoneNumber,
              creationTime: firebase.firestore.FieldValue.serverTimestamp(),
              lastSignInTime: firebase.firestore.FieldValue.serverTimestamp(),
              provider: 'google',
              language: 'english'
              // creationTime: googleUserData.user.metadata.creationTime,
              // lastSignInTime: googleUserData.user.metadata.lastSignInTime,
              // data:JSON.stringify(data)
              // metadata: getAuth().currentUser.metadata[0],
              // providerId: data.providerId,
              // providerData: data.providerData,
              // emailVerified: data.emailVerified,
              // rooms: {}
            })
          // setDoc(doc(firebase.firestore(), "userChats", googleUserData.user.uid))
          setDoc(doc(firebase.firestore(), "userChats", googleUserData.user.uid), {})
          navigation.navigate('EditProfile', { userID: firebase.auth()?.currentUser?.uid })

          // setDoc(doc(firebase.firestore(), "userChats", data.uid), {})
        }

        if (firebase.auth()?.currentUser?.metadata?.creationTime != firebase.auth()?.currentUser?.metadata?.lastSignInTime) {
          firebase
            .firestore()
            .collection('Users')
            .doc(getAuth()?.currentUser?.uid)
            .update({
              // name: data.displayName,
              // email: data.email,
              // uid: data.uid,
              // photo: data.photoURL,
              // phoneNumber: data.phoneNumber,
              // creationTime: data.metadata.creationTime,
              lastSignInTime: firebase.firestore.FieldValue.serverTimestamp(),
              // lastSignInTime: googleUserData.user.metadata.lastSignInTime,
            })
          firebase
            .firestore()
            .collection('activityLog')
            .doc(getAuth()?.currentUser?.uid)
            .collection('activityLog')
            .add({
              signIn: firebase.firestore.FieldValue.serverTimestamp(),
              // lastSignInTime: googleUserData.user.metadata.lastSignInTime,
            })
          navigation.navigate('EditProfile', { userID: firebase.auth()?.currentUser?.uid })

        }
      })
      // setisUser(auth)
    }
    setloading(false)

    // onAuthStateChanged(getAuth(), (data) => {

    //   if (data) {
    //     setloading(true)
    //     console.log(data)

    // console.log(data)
    // setisUser(data)
    // return (

    //       <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
    //             <Text>welcome {data.displayName}</Text>
    //             <Text>You Can Edit Your Profile Here</Text>
    //       </View>
    // )
    // setUserInfo(data)

    //   } else {
    //     setisUser(null)
    //   }
    // }
    // )
    // setloading(false)
  }, [response])

  // const saveUserData = () => {
  //   if (isUser === null) {
  //     return 
  //     // .then(alert('Login Using Google Is Successful!'))
  //     // .then(navigation.popToTop())
  //     // setUserInfo(data);
  //   } else if (isUser) {
  //     return
  //   }
  // }


  return (
    <TouchableOpacity
      style={{
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: 'green'
        // marginHorizontal: screenSize.width / 50,
      }}
      disabled={!request}
      onPress={() => { promptAsync(); }}>
      {loading ? <ActivityIndicator size='small'></ActivityIndicator> : null}
      <Image style={{ height: 48, width: 48 }} source={require('../assets/google.png')}></Image>
      {/* <Image style={{ height: 66, width: 66 }} source={{ uri: 'https://image.similarpng.com/very-thumbnail/2020/06/Logo-google-icon-PNG.png' }}></Image> */}
      <Text style={{ fontSize: 10, fontWeight: "900" }}>
        Google
      </Text>
    </TouchableOpacity>
  );
} ``