import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Image, TouchableOpacity } from "react-native";
import firebase from "../firebase/firebaseConfig";
import { doc, updateDoc, serverTimestamp, getDoc, setDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ScreenSize from "../components/ScreenSize";
import { GiftedChat, MessageText, MessageTextProps, IMessage, InputToolbar, Message } from 'react-native-gifted-chat'
import { MaterialCommunityIcons, } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import Hyperlink from 'react-native-hyperlink'
import * as Linking from "expo-linking";

export default function ChatScreen(props) {
  const combinedId = getAuth().currentUser?.uid > props.route.params?.userID ? getAuth().currentUser?.uid + props.route.params?.userID : props.route.params?.userID + getAuth().currentUser?.uid
  const [userData, setuserData] = useState(null);
  const [userB, setuserB] = useState([]);
  const [messages, setMessages] = useState([]);
  // const [userChatsRef, setUserChatsRef] = useState(null);
  const userID = props.route.params?.userID;
  // const combinedId = userData?.uid > props.route.params?.userID ? userData?.uid + props.route.params?.userID : props.route.params?.userID + userData?.uid
  // const combinedId = userData?.uid > userID ? userData?.uid + userID : userID + userData?.uid
  const screenSize = ScreenSize();


  useEffect(() => {
    if (messages?.length !== 0) {
      // console.log(messages?.length)
      // console.log(combinedId)
      // console.log(querySnapshot.docs.length, '/', data?.uid, '/', combinedId)
      updateDoc(doc(firebase.firestore(), "userChats", getAuth().currentUser?.uid), {
        // updateDoc(doc(firebase.firestore(), "userChats", chat[1].userInfo?.uid), {
        [combinedId + ".unreadMessagesNumber"]: 0,
        // [data?.uid > props.route.params?.userID ? data?.uid + props.route.params?.userID : props.route.params?.userID + data?.uid
        //   + ".unreadMessagesNumber"]: 0,
      });

      // updateDoc(doc(firebase.firestore(), "userChats", props.route.params?.userID), {
      //   // updateDoc(doc(firebase.firestore(), "userChats", chat[1].userInfo?.uid), {
      //   [combinedId + ".unreadMessagesNumber"]: 0,
      //   // [data?.uid > props.route.params?.userID ? data?.uid + props.route.params?.userID : props.route.params?.userID + data?.uid
      //   //   + ".unreadMessagesNumber"]: 0,
      // });
    } else { console.log('querySnapshot.empty') }
  }, [messages?.length])


  useEffect(() => {
    onAuthStateChanged(getAuth(), (data) => {
      if (data) {
        // setuserData(data)
        // try {
        // const fetchData = async () => {

        // getDoc(doc(firebase.firestore(), "userChats", userID)).then((snapshot) => {
        //   if (snapshot.exists) {
        //     setUserChatsRef(snapshot.data())
        //   }
        // })

        firebase.firestore()
          .collection("Users")
          .doc(firebase.auth().currentUser.uid)
          .get()
          .then((snapshot) => {
            setuserData(snapshot.data());
            // console.log(snapshot.data());
          });

        firebase.firestore()
          .collection("Users")
          .doc(userID)
          .get()
          .then((snapshot) => {
            setuserB(snapshot.data());
            // console.log(snapshot.data());
          });

        firebase
          .firestore()
          .collection("chats")
          .doc("combinedId")
          .collection(combinedId)
          // .collection(data?.uid > props.route.params?.userID ? data?.uid + props.route.params?.userID : props.route.params?.userID + data?.uid)
          .orderBy("createdAt", "desc")
          .onSnapshot((querySnapshot) => {
            // console.log(querySnapshot.docs.map((doc) => doc.data()))
            // if (!querySnapshot.empty) {
            //   // console.log(querySnapshot.docs.length, '/', data?.uid, '/', combinedId)
            //   updateDoc(doc(firebase.firestore(), "userChats", data?.uid), {
            //     // updateDoc(doc(firebase.firestore(), "userChats", chat[1].userInfo?.uid), {
            //     [combinedId + ".unreadMessagesNumber"]: 0,
            //     // [data?.uid > props.route.params?.userID ? data?.uid + props.route.params?.userID : props.route.params?.userID + data?.uid
            //     //   + ".unreadMessagesNumber"]: 0,
            //   });

            //   updateDoc(doc(firebase.firestore(), "userChats", props.route.params?.userID), {
            //     // updateDoc(doc(firebase.firestore(), "userChats", chat[1].userInfo?.uid), {
            //     [combinedId + ".unreadMessagesNumber"]: 0,
            //     // [data?.uid > props.route.params?.userID ? data?.uid + props.route.params?.userID : props.route.params?.userID + data?.uid
            //     //   + ".unreadMessagesNumber"]: 0,
            //   });
            // } else { console.log('querySnapshot.empty') }

            setMessages(querySnapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                // createdAt: doc.data().createdAt.toLocaleString()
                createdAt: doc.data().createdAt.toDate()
              }
            }));
          })

        // }
        // fetchData()

      } else {
        setuserData(null)
      }
    })
  }, [props.route.params?.userID, getAuth().currentUser?.uid]);

  // const userChatsRef = getDoc(doc(firebase.firestore(), "userChats", userID))

  async function handleSend(message) {
    const userChatsRef = await getDoc(doc(firebase.firestore(), "userChats", userID))
    // {{{ not to update users info with every message }}}
    // if (messages.length != 0) {
    // await updateDoc(doc(firebase.firestore(), "userChats", userData?.uid), {
    //   [combinedId + ".userInfo"]: {
    //     uid: userB?.uid,
    //     displayName: userB?.displayName,
    //     photoURL: userB?.photoURL,
    //   },
    //  }
    // }

    // setDoc(doc(firebase.firestore(), "userChats", googleUserData.user.uid), {})

    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, message)
    );

    message.map((m) =>
      firebase
        .firestore()
        .collection("chats")
        .doc("combinedId")
        .collection(combinedId)
        .add(m)
    );

    if (messages.length !== 0) {
      // console.log('messages.length > 0')
      await updateDoc(doc(firebase.firestore(), "userChats", userData?.uid), {
        // [combinedId + ".userInfo"]: {
        //   uid: userB?.uid,
        //   displayName: userB?.displayName,
        //   photoURL: userB?.photoURL,
        // },

        [combinedId + ".lastMessage"]: message[0].text,

        [combinedId + ".date"]: serverTimestamp(),
      });


      await updateDoc(doc(firebase.firestore(), "userChats", userID), {
        // [combinedId + ".userInfo"]: {
        //   uid: userData?.uid,
        //   displayName: userData?.displayName,
        //   photoURL: userData?.photoURL,
        // },

        // [combinedId + ".unreadMessagesNumber"]: userChatsRef[combinedId]?.unreadMessagesNumber + 1,
        [combinedId + ".unreadMessagesNumber"]: userChatsRef.data()[combinedId]?.unreadMessagesNumber + 1,

        [combinedId + ".lastMessage"]: message[0].text,

        [combinedId + ".date"]: serverTimestamp(),
      });

    } else if (messages.length === 0) {
      // console.log('messages.length === 0')
      await setDoc(doc(firebase.firestore(), "userChats", userData?.uid), {
        [combinedId]: {
          userInfo: {
            uid: userB?.uid,
            displayName: userB?.displayName,
            photoURL: userB?.photoURL,
          },

          unreadMessagesNumber: 0,

          lastMessage: message[0].text,

          date: serverTimestamp()
        }
      })

      await setDoc(doc(firebase.firestore(), "userChats", userID), {
        [combinedId]: {
          userInfo: {
            uid: userData?.uid,
            displayName: userData?.displayName,
            photoURL: userData?.photoURL,
          },

          unreadMessagesNumber: 1,

          lastMessage: message[0].text,

          date: serverTimestamp(),
        }
      })
    }


    // console.log(userChatsRef.data()[combinedId]?.unreadMessagesNumber + 1)
  }

  const MessengerBarContainer = (props) => {
    return (
      <InputToolbar
        {...props}
        containerStyle={{
          backgroundColor: '#fff',
          alignContent: "center",
          // justifyContent: "center",
          borderWidth: 0,
          marginHorizontal: 5,
          borderRadius: 32,
          borderTopColor: "transparent",
          marginBottom: 3,
          // marginTop: -3
          // paddingVertical: 20,
          // paddingTop: 6,
          // marginVertical: 3,
        }}
      />
    );
  };

  // const convertLinks = (input) => {

  //   let text = input;
  //   const linksFound = text.match(/(?:www|https?)[^\s]+/g);
  //   const aLink = [];

  //   if (linksFound != null) {

  //     for (let i = 0; i < linksFound.length; i++) {
  //       let replace = linksFound[i];
  //       if (!(linksFound[i].match(/(http(s?)):\/\//))) { replace = 'http://' + linksFound[i] }
  //       let linkText = replace.split('/')[2];
  //       if (linkText.substring(0, 3) == 'www') { linkText = linkText.replace('www.', '') }
  //       if (linkText.match(/youtu/)) {

  //         let youtubeID = replace.split('/').slice(-1)[0];
  //         aLink.push('<div class="video-wrapper"><iframe src="https://www.youtube.com/embed/' + youtubeID + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>')
  //       }
  //       else if (linkText.match(/vimeo/)) {
  //         let vimeoID = replace.split('/').slice(-1)[0];
  //         aLink.push('<div class="video-wrapper"><iframe src="https://player.vimeo.com/video/' + vimeoID + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>')
  //       }
  //       else {
  //         aLink.push('<a href="' + replace + '" target="_blank">' + linkText + '</a>');
  //       }
  //       text = text.split(linksFound[i]).map(item => { return aLink[i].includes('iframe') ? item.trim() : item }).join(aLink[i]);
  //     }
  //     return text;

  //   }
  //   else {
  //     return input;
  //   }
  // }

  // const linkify = t => {
  //   const isValidHttpUrl = s => {
  //     let u
  //     try { u = new URL(s) }
  //     catch (_) { return false }
  //     return u.protocol.startsWith("http")
  //   }
  //   const m = t.match(/(?<=\s|^)[a-zA-Z0-9-:/]+\.[a-zA-Z0-9-].+?(?=[.,;:?!-]?(?:\s|$))/g)
  //   if (!m) return t
  //   const a = []
  //   m.forEach(x => {
  //     const [t1, ...t2] = t.split(x)
  //     a.push(t1)
  //     t = t2.join(x)
  //     const y = (!(x.match(/:\/\//)) ? 'https://' : '') + x
  //     if (isNaN(x) && isValidHttpUrl(y))
  //       a.push('<a href="' + y + '" target="_blank">' + y.split('/')[2] + '</a>')
  //     else
  //       a.push(x)
  //   })
  //   a.push(t)
  //   return a.join('')
  // }
  // document.querySelectorAll('.linkify-this').forEach(o => {
  //   o.innerHTML = linkify(o.innerHTML)
  // })

  // function linkify(inputText) {
  //   var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //   //URLs starting with http://, https://, or ftp://
  //   replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  //   replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //   //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  //   replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  //   replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  //   //Change email addresses to mailto:: links.
  //   replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  //   replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  //   return replacedText;
  // }

  // const linkify = input => {
  //   let text = input;
  //   const aLink = [];
  //   const linksFound = text.match(/(?:www|https?)[^\s]+/g);

  //   if (linksFound != null) {
  //     for (let i = 0; i < linksFound.length; i++) {
  //       let replace = linksFound[i];

  //       if (!(linksFound[i].match(/(http(s?)):\/\//))) {
  //         replace = 'http://' + linksFound[i]
  //       }

  //       let linkText = replace.split('/')[2];

  //       if (linkText.substring(0, 3) == 'www') {
  //         linkText = linkText.replace('www.', '')
  //       }

  //       if (linkText.match(/youtu/)) {
  //         const youtubeID = replace.split('/').slice(-1)[0].split('=')[1];

  //         if (youtubeID === undefined || youtubeID === '') {
  //           aLink.push('<a href="' + replace + '" target="_blank">' + linkText + '</a>');
  //         } else {
  //           aLink.push('<span class="video-wrapper"><iframe src="https://www.youtube.com/embed/' + youtubeID + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></span>');
  //         }
  //       } else {
  //         aLink.push('<a href="' + replace + '" target="_blank">' + linkText + '</a>');
  //       }

  //       text = text.split(linksFound[i]).map(item => {
  //         return aLink[i].includes('iframe') ? item.trim() : item
  //       }).join(aLink[i]);
  //     }
  //     return text;
  //   }
  //   else {
  //     return input;
  //   }
  // };
  const text = 'Can you tell me more about that product ? https://localhost:19006/ProductInfo?productId=rrcNmKJC6b4hft2bbeRD'

  // const handleMesseges = (m) => {
  //   <Hyperlink linkDefault={true}>
  //     <Text style={{ fontSize: 15 }}>
  //       {m}
  //     </Text>
  //   </Hyperlink>
  // }

  // const CustomMessageText = (props) => {
  //   <MessageText
  //     {...props}
  //     linkStyle={{
  //       right: {
  //         color: 'pink'
  //       },
  //       left: {
  //         color: 'orange'
  //       }
  //     }}
  //   />
  // }

  const renderMessage = (props) => {
    return (
      <Message
        {...props}
        linkStyle={{
          right: {
            color: 'black'
          },
          left: {
            color: 'orange'
          }
        }}
      />
    )
  }

  if (userData && messages) {
    const date = new Date(userB?.lastSignInTime && userB?.lastSignInTime.toDate().toDateString());
    const displayDate =
      date.getDate()
      + "/" + (date.getMonth() + 1)
      + "/" + (date.getFullYear())
    // + "/" + (date.getHours())
    // + "/" + (date.getMinutes())

    return (
      <View style={{ flex: 1 }}>
        <LinearGradient style={{ flex: 1 }} colors={[
          '#DAE2F8', '#DAE2F8', '#D6A4A4'
          // '#FFFFFF', '#6DD5FA', '#2980B9'
          // #93A5CF → #E4EfE9
        ]}>

          {/* <ImageBackground
          style={{ flex: 1 }}
          source={{
            uri: "https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png",
          }}
        > */}
          <View style={{ flex: 1, paddingHorizontal: screenSize.isWide ? screenSize.width / 5.35 : 0 }}>

            <View style={{ width: '100%', height: screenSize.height / 11, backgroundColor: 'transparent', justifyContent: "center", borderRadius: 30 }}>
              <View style={{ alignItems: 'center', marginLeft: 6, flexDirection: 'row', paddingVertical: 2 }}>
                <TouchableOpacity style={{ marginRight: 6 }} onPress={() => props.navigation.navigate('Chats')}>
                  <MaterialCommunityIcons name="arrow-left" size={screenSize.height / 30} color='#676767' />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => {
                  props.navigation.navigate('MyAccount', {
                    // userB: userB,
                    profileID: userB.uid
                  })
                }} style={{ alignItems: 'center', flexDirection: 'row' }}>
                  <Image source={{ uri: userB?.photoURL }} style={{
                    height:
                      screenSize.height / 13,
                    // screenSize.ratio(25),
                    width:
                      screenSize.height / 13,
                    // screenSize.ratio(25),
                    borderRadius: 300,
                    marginRight: 10
                  }} />
                  <View>
                    <Text style={{ fontSize: 16, fontWeight: '600', color: "#676767" }}>
                      {userB?.displayName}
                    </Text>
                    <Text style={{ fontSize: 10, fontWeight: '400', color: "#676767" }}>
                      {/* Last Seen: {userB?.lastSignInTime.toDateString()} */}
                      Last Seen: {displayDate} At: {userB?.lastSignInTime && userB?.lastSignInTime.toDate().toLocaleTimeString()}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            {/* <Text>{linkify(text)}</Text> */}
            <GiftedChat
              messages={messages}
              onSend={handleSend}
              renderAvatar={null}
              user={{
                _id: userData?.uid,
                name: userData?.displayName,
                avatar: userData?.photoURL,
              }}
              // initialText={() => <Hyperlink linkDefault={true}>
              //   <Text style={{ fontSize: 15 }}>
              //     This text will be parsed to check for clickable strings like https://github.com/obipawan/hyperlink and made clickable.
              //   </Text>
              // </Hyperlink>}
              initialText={props.route.params?.product ? props.route.params?.product : null}
              // initialText={props.route.params?.product ? JSON.stringify(props.route.params?.product) : null}
              textInputProps={{
                autoFocus: true,
                blurOnSubmit: false
              }}
              isKeyboardInternallyHandled={false}
              keyboardShouldPersistTaps="always"
              renderInputToolbar={(props) => MessengerBarContainer(props)}
              alwaysShowSend
              renderMessage={renderMessage}
            // renderMessageText={CustomMessageText}

            // parsePatterns={(item) => [
            //   {
            //     type: "url",
            //     style: {
            //       textDecorationLine: "underline",
            //       // color:
            //       //   item && item[0].color === "black"
            //       // ? themeContext.chatScreen.linksColorLeft
            //       // : themeContext.chatScreen.linksColorRight
            //     },
            //     onPress: () => { Linking.openURL(`viber://chat?number=0123456789`) }
            //     // onPress: async (text) => {
            //     //   await handleLink(text, navigation);
            //     // }
            //   },
            //   // {
            //   //   type: "phone",
            //   //   style: {
            //   //     color:
            //   //       // @ts-ignore
            //   //       item && item[0].color === "black"
            //   //     // ? themeContext.chatScreen.linksColorLeft
            //   //     // : themeContext.chatScreen.linksColorRight
            //   //   },
            //   //   onPress: callNumber
            //   // },
            //   // {
            //   //   type: "email",
            //   //   style: {
            //   //     color:
            //   //       // @ts-ignore
            //   //       item && item[0].color === "black"
            //   //     // ? themeContext.chatScreen.emailColorLeft
            //   //     // : themeContext.chatScreen.emailColorRight
            //   //   },
            //   //   onPress: sendEmailTo
            //   // }
            // ]}

            // text={props.product}
            // renderAvatarOnTop={true}
            // showUserAvatar={true}
            // renderUserName
            // renderUsernameOnMessage
            // showAvatarForEveryMessage
            // renderAvatarOnTop={true}
            // scrollToBottom
            // key={messages.id}
            />
          </View>
        </LinearGradient>
        {/* </ImageBackground> */}
      </View>
    )
  } else if (!userData) {
    return <Text>You Are Not Logged In</Text>;
  } else {
    return <Text>Start Typing !</Text>
  }

  // if (userData && messages) {
  //   return (
  //     <View style={styles.container}>
  //       <Text>
  //         You have to log in
  //       </Text>
  //     </View>
  //   )
  // }

  // else if (!isUser) {
  //   return (
  //     <View style={styles.container}>
  //       <Text>
  //         You have to log in
  //       </Text>
  //     </View>
  //   )
  // }

  // else {
  //   return (
  //     <View style={styles.container}>
  //       <Text>
  //         Start Typing !
  //       </Text>
  //     </View>
  //   )
  // }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  input: {
    height: 50,
    width: "100%",
    borderWidth: 1,
    padding: 15,
    marginBottom: 20,
    borderColor: "gray",
  },
});


// import React, { useState, useEffect } from "react";
// import { StyleSheet, View, Text, Image, TouchableOpacity } from "react-native";
// import firebase from "../firebase/firebaseConfig";
// import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import ScreenSize from "../components/ScreenSize";
// import { GiftedChat, InputToolbar } from "react-native-gifted-chat";
// import { MaterialCommunityIcons, } from '@expo/vector-icons';
// import { LinearGradient } from 'expo-linear-gradient';

// export default function ChatScreen(props) {
//   const [userData, setuserData] = useState(null);
//   const [messages, setMessages] = useState([]);
//   const [userB, setuserB] = useState([]);
//   const userID = props.route.params?.userID;
//   const combinedId = userData?.uid > props.route.params?.userID ? userData?.uid + props.route.params?.userID : props.route.params?.userID + userData?.uid
//   // const combinedId = userData?.uid > userID ? userData?.uid + userID : userID + userData?.uid
//   const screenSize = ScreenSize();


//   useEffect(() => {
//     onAuthStateChanged(getAuth(), (data) => {
//       if (data) {
//         // setuserData(data)
//         // try {
//         // const fetchData = async () => {

//         firebase.firestore()
//           .collection("Users")
//           .doc(firebase.auth().currentUser.uid)
//           .get()
//           .then((snapshot) => {
//             setuserData(snapshot.data());
//             // console.log(snapshot.data());
//           });

//         firebase.firestore()
//           .collection("Users")
//           .doc(userID)
//           .get()
//           .then((snapshot) => {
//             setuserB(snapshot.data());
//             // console.log(snapshot.data());
//           });

//         firebase
//           .firestore()
//           .collection("chats")
//           .doc("combinedId")
//           .collection(data?.uid > props.route.params?.userID ? data?.uid + props.route.params?.userID : props.route.params?.userID + data?.uid)
//           .orderBy("createdAt", "desc")
//           .onSnapshot((querySnapshot) => {
//             // console.log(querySnapshot.docs.map((doc) => doc.data()))
//             setMessages(querySnapshot.docs.map((doc) => {
//               return {
//                 ...doc.data(),
//                 // createdAt: doc.data().createdAt.toLocaleString()
//                 createdAt: doc.data().createdAt.toDate()
//               }
//             }));
//           })

//         // }
//         // fetchData()

//       } else {
//         setuserData(null)
//       }
//     })
//   }, [props.route.params?.userID]);


//   async function handleSend(messages) {
//     // setDoc(doc(firebase.firestore(), "userChats", googleUserData.user.uid), {})

//     setMessages((previousMessages) =>
//       GiftedChat.append(previousMessages, messages)
//     );

//     messages.map((m) =>
//       firebase
//         .firestore()
//         .collection("chats")
//         .doc("combinedId")
//         .collection(combinedId)
//         .add(m)
//     );


//     await updateDoc(doc(firebase.firestore(), "userChats", userData?.uid), {
//       [combinedId + ".userInfo"]: {
//         uid: userB?.uid,
//         displayName: userB?.displayName,
//         photoURL: userB?.photoURL,
//       },

//       [combinedId + ".lastMessage"]: messages[0].text,

//       [combinedId + ".date"]: serverTimestamp(),
//     });

//     console.log(userData)
//     await updateDoc(doc(firebase.firestore(), "userChats", userID), {
//       [combinedId + ".userInfo"]: {
//         uid: userData?.uid,
//         displayName: userData?.displayName,
//         photoURL: userData?.photoURL,
//       },

//       [combinedId + ".lastMessage"]: messages[0].text,

//       [combinedId + ".date"]: serverTimestamp(),
//     });
//   }

//   const MessengerBarContainer = (props) => {
//     return (
//       <InputToolbar
//         {...props}
//         containerStyle={{
//           backgroundColor: '#fff',
//           alignContent: "center",
//           // justifyContent: "center",
//           borderWidth: 0,
//           marginHorizontal: 5,
//           borderRadius: 32,
//           borderTopColor: "transparent",
//           marginBottom: 3,
//           // marginTop: -3
//           // paddingVertical: 20,
//           // paddingTop: 6,
//           // marginVertical: 3,
//         }}
//       />
//     );
//   };


//   if (userData && messages) {
//     const date = new Date(userB?.lastSignInTime && userB?.lastSignInTime.toDate().toDateString());
//     const displayDate =
//       date.getDate()
//       + "/" + (date.getMonth() + 1)
//       + "/" + (date.getFullYear())
//     // + "/" + (date.getHours())
//     // + "/" + (date.getMinutes())

//     return (
//       <View style={{ flex: 1 }}>
//         <LinearGradient style={{ flex: 1 }} colors={[
//           '#DAE2F8', '#DAE2F8', '#D6A4A4'
//           // '#FFFFFF', '#6DD5FA', '#2980B9'
//           // #93A5CF → #E4EfE9
//         ]}>

//           {/* <ImageBackground
//           style={{ flex: 1 }}
//           source={{
//             uri: "https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png",
//           }}
//         > */}
//           <View style={{ flex: 1, paddingHorizontal: screenSize.isWide ? screenSize.width / 5.35 : 0 }}>

//             <View style={{ width: '100%', height: screenSize.height / 11, backgroundColor: 'transparent', justifyContent: "center", borderRadius: 30 }}>
//               <View style={{ alignItems: 'center', marginLeft: 6, flexDirection: 'row', paddingVertical: 2 }}>
//                 <TouchableOpacity style={{ marginRight: 6 }} onPress={() => props.navigation.navigate('ChatsScreen')}>
//                   <MaterialCommunityIcons name="arrow-left" size={screenSize.height / 30} color='#676767' />
//                 </TouchableOpacity>

//                 <TouchableOpacity onPress={() => {
//                   props.navigation.navigate('MyAccount', {
//                     // userB: userB,
//                     profileID: userB.uid
//                   })
//                 }} style={{ alignItems: 'center', flexDirection: 'row' }}>
//                   <Image source={{ uri: userB?.photoURL }} style={{
//                     height:
//                       screenSize.height / 13,
//                     // screenSize.ratio(25),
//                     width:
//                       screenSize.height / 13,
//                     // screenSize.ratio(25),
//                     borderRadius: 300,
//                     marginRight: 10
//                   }} />
//                   <View>
//                     <Text style={{ fontSize: 16, fontWeight: '600', color: "#676767" }}>
//                       {userB?.displayName}
//                     </Text>
//                     <Text style={{ fontSize: 10, fontWeight: '400', color: "#676767" }}>
//                       {/* Last Seen: {userB?.lastSignInTime.toDateString()} */}
//                       Last Seen: {displayDate} At: {userB?.lastSignInTime && userB?.lastSignInTime.toDate().toLocaleTimeString()}
//                     </Text>
//                   </View>
//                 </TouchableOpacity>
//               </View>
//             </View>

//             <GiftedChat
//               messages={messages}
//               onSend={handleSend}
//               renderAvatar={null}
//               user={{
//                 _id: userData?.uid,
//                 name: userData?.displayName,
//                 avatar: userData?.photoURL,
//               }}
//               initialText={props.route.params?.product ? JSON.stringify(props.route.params?.product) : null}
//               textInputProps={{
//                 autoFocus: true,
//                 blurOnSubmit: false
//               }}
//               isKeyboardInternallyHandled={false}
//               keyboardShouldPersistTaps="always"
//               renderInputToolbar={(props) => MessengerBarContainer(props)}
//               alwaysShowSend
//             // text={props.product}
//             // renderAvatarOnTop={true}
//             // showUserAvatar={true}
//             // renderUserName
//             // renderUsernameOnMessage
//             // showAvatarForEveryMessage
//             // renderAvatarOnTop={true}
//             // scrollToBottom
//             // key={messages.id}
//             />
//           </View>
//         </LinearGradient>
//         {/* </ImageBackground> */}
//       </View>
//     )
//   } else if (!userData) {
//     return <Text>You Are Not Logged In</Text>;
//   } else {
//     return <Text>Start Typing !</Text>
//   }

//   // if (userData && messages) {
//   //   return (
//   //     <View style={styles.container}>
//   //       <Text>
//   //         You have to log in
//   //       </Text>
//   //     </View>
//   //   )
//   // }

//   // else if (!isUser) {
//   //   return (
//   //     <View style={styles.container}>
//   //       <Text>
//   //         You have to log in
//   //       </Text>
//   //     </View>
//   //   )
//   // }

//   // else {
//   //   return (
//   //     <View style={styles.container}>
//   //       <Text>
//   //         Start Typing !
//   //       </Text>
//   //     </View>
//   //   )
//   // }
// }

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: "#fff",
//     alignItems: "center",
//     justifyContent: "center",
//     padding: 30,
//   },
//   input: {
//     height: 50,
//     width: "100%",
//     borderWidth: 1,
//     padding: 15,
//     marginBottom: 20,
//     borderColor: "gray",
//   },
// });
