import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

import { COLOURS } from '../components/database/Database';

export default function AppButton({ title, onPress, color = 'primary' }) {
  return (
    <TouchableOpacity
      style={[styles.button, { backgroundColor: COLOURS[color] }]}
      onPress={onPress}
    >
      <Text style={styles.buttonText}>{title}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    // marginVertical: 10,
    // borderRadius: 25,
    // justifyContent: 'center',
    // alignItems: 'center',
    // padding: 15,
    // width: '100%'
    backgroundColor: "#2f97ca", borderRadius: 20, justifyContent: 'center', alignItems: 'center', paddingVertical: 15, width: '100%',
    marginVertical: 4
  },
  buttonText: {
    // color: COLOURS.black,
    // fontSize: 18,
    // fontWeight: '600',
    // textTransform: 'uppercase'
    fontSize: 20, fontWeight: 'bold', alignItems: 'center', color: '#fff'
  }
});
