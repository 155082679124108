import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native'
import React from 'react'
import ScreenSize from '../components/ScreenSize'
import { LinearGradient } from 'expo-linear-gradient'
import Feather from '@expo/vector-icons/Feather';
import { BlurView } from 'expo-blur';
import * as Linking from "expo-linking";
// import Svg, { Path, Circle, Rect } from "react-native-svg"

const ContactScreen = () => {
      const screenSize = ScreenSize()
      return (
            <View style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden'
            }}>
                  <Image source={require('../assets/svg.png')} style={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        // backgroundColor: '#768fc8',
                        borderBottomLeftRadius: 25,
                        borderBottomRightRadius: 25,
                        top: 0
                  }} />
                  {/* <LinearGradient style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden'
            }}
                  colors={[
                        // '#FFFFFF', '#6DD5FA', '#2980B9'
                        '#93A5CF', '#E4EfE9'
                  ]}
            > */}
                  {!screenSize.isWide &&
                        // <Image source={require('../assets/svg.png')} style={{
                        //       height: '100%',
                        //       width: '100%',
                        //       position: 'absolute',
                        //       backgroundColor: '#768fc8',
                        //       borderBottomLeftRadius: 25,
                        //       borderBottomRightRadius: 25,
                        //       top: 0
                        // }} />
                        // :
                        <Image source={require('../assets/contactUs3.jpg')} style={{
                              height: '25%',
                              width: '100%',
                              position: 'absolute',
                              borderBottomLeftRadius: 25,
                              borderBottomRightRadius: 25,
                              top: 0
                              // backgroundColor: '#768fc8',
                        }} />
                  }





                  {/* <Image source={require('../assets/contactUs7.jpg')} style={{
                        height: '35%',
                        width: '35%',
                        resizeMode: 'cover',
                        // backgroundColor: '#768fc8',
                        // position: 'absolute',
                        // borderBottomLeftRadius: 25,
                        // borderBottomRightRadius: 25,
                        top: 7.5
                  }} /> */}





                  <View style={{
                        // position: 'absolute',
                        // top: '20%',
                        width: screenSize.isWide ? '40%' : '85%',
                        height: screenSize.isWide ? '55%' : '40%',
                        bottom: 60
                        // flex: 1
                  }}>
                        <View style={{ height: '100%', width: '100%', overflow: 'hidden', }}>
                              {/* <Image source={require('../assets/contactUs4.png')}
                                    style={{
                                          height: '100%',
                                          width: '100%',
                                          resizeMode: 'stretch',
                                          position: 'absolute',
                                          borderRadius: 30,
                                          // width: 500,
                                          // height: 300,
                                          // overflow: 'hidden',
                                          // opacity: 0.4,
                                          // top: '20%'
                                    }} >

                              </Image> */}
                              <Image source={require('../assets/contactUs6.jpg')} style={{
                                    height: '100%',
                                    width: '100%',
                                    resizeMode: 'cover',
                                    position: 'absolute',
                                    // borderBottomLeftRadius: 25,
                                    // borderBottomRightRadius: 25,
                                    borderRadius: 25
                              }} />
                              <BlurView
                                    intensity={10}
                                    tint='dark'
                                    // tint='light'
                                    style={{
                                          flex: 1,
                                          overflow: 'hidden',
                                          borderRadius: 30,
                                          justifyContent: 'center',
                                          alignItems: 'center',

                                    }} >

                                    <Text style={{
                                          color: '#fff',
                                          // color: '#dbdbdb',
                                          // color: '#288895',
                                          // color: '#093b42',
                                          fontSize: screenSize.ratio(45),
                                          fontWeight: '800',
                                          textAlign: 'center',
                                          marginHorizontal: 20,
                                          fontFamily: 'Santana'
                                          // top: 200
                                    }}>
                                          {'We Are Glad To Hear From You Any Time Of The Day\n24/7 In Your Service\nOur Team Is Always Here For You'}
                                    </Text>
                              </BlurView>
                        </View>

                        <View style={{
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              position: 'absolute',
                              width: '100%',
                              bottom: -screenSize.ratio(25),
                              alignItems: 'center',
                              borderRadius: 60,
                              // backgroundColor: 'red'
                        }}>
                              <View style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    // backgroundColor: 'red'
                              }}>
                                    <TouchableOpacity
                                          onPress={() => {
                                                Linking.openURL(`tel:+201555531216`);
                                          }}
                                          style={{
                                                backgroundColor: '#f6f6f6',
                                                borderRadius: 1000,
                                                height: screenSize.ratio(12),
                                                width: screenSize.ratio(12),
                                                elevation: 5,
                                                zIndex: 1,
                                                shadowColor: '#828282',
                                                shadowOffset: { width: 2, height: 3 },
                                                shadowOpacity: 1,
                                                shadowRadius: 10,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderColor: '#c1c1c1',
                                                borderWidth: 1,
                                          }}>
                                          <Feather name="phone" size={34} color="#288895" />
                                    </TouchableOpacity>
                                    <Text style={{
                                          color:
                                                // '#ededed',
                                                '#288895',
                                          fontSize: screenSize.ratio(75), fontWeight: '500', position: 'absolute', bottom: -23
                                    }}>+201555531216</Text>
                              </View>

                              <View style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    // backgroundColor: 'red'
                              }}>
                                    <TouchableOpacity
                                          onPress={() => {
                                                Linking.openURL('mailto:lapmarketkms@gmail.com').catch(error => {
                                                      console.log(error);
                                                })
                                          }}
                                          style={{
                                                backgroundColor: '#f6f6f6',
                                                borderRadius: 1000,
                                                height: screenSize.ratio(12),
                                                width: screenSize.ratio(12),
                                                elevation: 5,
                                                zIndex: 1,
                                                shadowColor: '#828282',
                                                shadowOffset: { width: 2, height: 3 },
                                                shadowOpacity: 1,
                                                shadowRadius: 10,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderColor: '#c1c1c1',
                                                borderWidth: 1,
                                          }}>
                                          <Feather name="mail" size={34} color="#288895" />
                                    </TouchableOpacity>
                                    <Text style={{
                                          color:
                                                // '#ededed',
                                                '#288895',
                                          fontSize: screenSize.ratio(75), fontWeight: '500', position: 'absolute', bottom: -23
                                    }}>lapmarketkms@gmail.com</Text>
                              </View>

                        </View>
                        {/* <View style={{
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              position: 'absolute',
                              width: '100%',
                              bottom: -screenSize.ratio(30),
                              alignItems: 'center',
                              borderRadius: 60,
                              // backgroundColor: 'red'
                        }}>
                              <TouchableOpacity style={{
                                    backgroundColor: '#f6f6f6',
                                    borderRadius: 1000,
                                    height: screenSize.ratio(10),
                                    width: "49%",
                                    // width: screenSize.ratio(6),
                                    elevation: 5,
                                    zIndex: 1,
                                    shadowColor: '#828282',
                                    shadowOffset: { width: 2, height: 3 },
                                    shadowOpacity: 1,
                                    shadowRadius: 10,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderColor: '#c1c1c1',
                                    borderWidth: 1,
                              }}>
                                    <Feather name="phone" size={34} color="#288895" />
                                    <Text style={{ color: '#288895', fontSize: screenSize.ratio(75), fontWeight: '400', marginTop: 1 }}>01555531216</Text>
                              </TouchableOpacity>

                              <TouchableOpacity style={{
                                    backgroundColor: '#f6f6f6',
                                    borderRadius: 1000,
                                    height: screenSize.ratio(10),
                                    width: "49%",
                                    // width: screenSize.ratio(6),
                                    elevation: 5,
                                    zIndex: 1,
                                    shadowColor: '#828282',
                                    shadowOffset: { width: 2, height: 3 },
                                    shadowOpacity: 1,
                                    shadowRadius: 10,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderColor: '#c1c1c1',
                                    borderWidth: 1,
                              }}>
                                    <Feather name="mail" size={34} color="#288895" />
                                    <Text style={{ color: '#288895', fontSize: screenSize.ratio(75), fontWeight: '400', marginTop: 1 }}>lapmarketkms@gmail.com</Text>
                              </TouchableOpacity>

                        </View> */}
                  </View>
                  {/* </LinearGradient> */}
            </View>
      )
}

export default ContactScreen

const styles = StyleSheet.create({
      blurContainer: {
            flex: 1,
            padding: 20,
            margin: 16,
            textAlign: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            borderRadius: 20,
      }
})