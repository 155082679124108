import React, { useEffect, useState } from "react";
import {
  LogBox,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Dropdown } from "react-native-element-dropdown";
// import axios from "axios";
// import {BASE_URL, API_KEY} from '@env';
import {
  State,
  // Country,
  // City
} from "country-state-city";
// import {
//   CitySelect,
//   // CountrySelect,
//   StateSelect,
//   // LanguageSelect,
// } from "react-country-state-city";
// import MapPreview from "../components/MapPreview";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  GetCountries,
  GetState,
  GetCity,
  // GetLanguages, //async functions
} from "react-country-state-city";
import ScreenSize from "../components/ScreenSize";

const CityDropDown = (props) => {

  const egypt = {
    name: "Egypt",
    capital: "Cairo",
    currency: "EGP",
    currency_name: "Egyptian pound",
    currency_symbol: "ج.م",
    emoji: "🇪🇬",
    id: 65,
    iso2: "EG",
    iso3: "EGY",
    latitude: "27.00000000",
    longitude: "30.00000000",
    name: "Egypt",
    native: "مصر‎",
    numeric_code: "818",
    phone_code: 20,
    region: "Africa",
    subregion: "Northern Africa",
    tld: ".eg",
    _index: 0
  }
  const [country, setCountry] = useState(egypt);
  const [state, setstate] = useState(null);
  const [city, setCity] = useState(null);
  const [isFocus, setIsFocus] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [coordinates, setCoordinates] = useState(null);
  const [address, setAddress] = useState("");
  const [zoom, setZoom] = useState(null);

  // const BASE_URL = "https://api.countrystatecity.in/v1";
  // const API_KEY = "NkFmdXBhWUkyaDNmd3A2TWJqaVkwT09hMzI2eVRUUTNObGxZSlp0Vw==";

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
    LogBox.ignoreLogs(['Attempted import error:'])

    GetState(country.id).then((result) => {
      setStateList(result);
    })

  }, []);

  // useEffect(() => {
  // console.log(csc.getAllCountries());
  // console.log(csc.getStatesOfCountry(65));
  // console.log(csc.getCitiesOfState(3223));
  // console.log(csc.getStateById(3223));
  // csc.getCitiesOfState()
  // }, []);
  // ("sk-X9WE656a47267308a54");
  // useEffect(() => {
  //   // console.log(Country.getAllCountries());
  //   // console.log(City.getCitiesOfCountry("EG"));
  //   // console.log(City.getCitiesOfState("EG", "C"));
  //   // console.log(City.getAllCities());
  //   async function fetchData() {
  //     try {
  //       const response = await axios.get(
  // `https://continentl.com/api/country-list?page=1&key=sk-X9WE656a47267308a54`
  //       );
  //       setData(response.data);
  //       console.log(response.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }

  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   var config = {
  //     method: "get",
  //     url: "https://api.countrystatecity.in/v1/countries/EG/states/MN/cities/MN",
  //     headers: {
  //       "X-CSCAPI-KEY": API_KEY,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(JSON.stringify(response.data));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);

  // useEffect(() => {
  //   var config = {
  //     method: "get",
  //     url: "https://api.countrystatecity.in/v1/countries/EG/states/",
  //     headers: {
  //       "X-CSCAPI-KEY":
  //         "NkFmdXBhWUkyaDNmd3A2TWJqaVkwT09hMzI2eVRUUTNObGxZSlp0Vw==",
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(JSON.stringify(response.data));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // useEffect(() => {
  //   var config = {
  //     method: "get",
  //     url: `${BASE_URL}/countries`,
  //     headers: {
  //       "X-CSCAPI-KEY": API_KEY,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       // console.log(JSON.stringify(response.data));
  //       var count = Object.keys(response.data).length;
  //       let countryArray = [];
  //       for (var i = 0; i < count; i++) {
  //         countryArray.push({
  //           value: response.data[i].iso2,
  //           label: response.data[i].name,
  //         });
  //       }
  //       setCountryData(countryArray);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  // useEffect(() => {
  //   // console.log(countryCode)
  //   var config = {
  //     method: "get",
  //     url: `${BASE_URL}/countries/EG/states`,
  //     headers: {
  //       "X-CSCAPI-KEY": API_KEY,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       // console.log(JSON.stringify(response.data));
  //       var count = Object.keys(response.data).length;
  //       let stateArray = [];
  //       for (var i = 0; i < count; i++) {
  //         stateArray.push({
  //           value: response.data[i].iso2,
  //           label: response.data[i].name,
  //         });
  //       }
  //       setStateData(stateArray);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // const handleState = countryCode => {
  //   console.log(countryCode)
  //   var config = {
  //     method: 'get',
  //     url: `${BASE_URL}/countries/${countryCode}/states`,
  //     headers: {
  //       'X-CSCAPI-KEY': API_KEY,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(JSON.stringify(response.data));
  //       var count = Object.keys(response.data).length;
  //       let stateArray = [];
  //       for (var i = 0; i < count; i++) {
  //         stateArray.push({
  //           value: response.data[i].iso2,
  //           label: response.data[i].name,
  //         });
  //       }
  //       setStateData(stateArray);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const handleCity = (countryCode, stateCode) => {
  //   var config = {
  //     method: "get",
  //     url: `${BASE_URL}/countries/EG/states/${stateCode}/cities`,
  //     // url: `${BASE_URL}/countries/${countryCode}/states/${stateCode}/cities`,
  //     headers: {
  //       "X-CSCAPI-KEY": API_KEY,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(JSON.stringify(response.data));
  //       var count = Object.keys(response.data).length;
  //       let cityArray = [];
  //       for (var i = 0; i < count; i++) {
  //         cityArray.push({
  //           value: response.data[i].id,
  //           label: response.data[i].name,
  //         });
  //       }
  //       setCityData(cityArray);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const pickOnMapHandler = () => {
  //   window.open("https://maps.google.com?q=" + coordinates.lat + "," + coordinates.lng);
  // };

  return (
    <View
      style={{
        backgroundColor: "#eaeaea",
        borderRadius: 7,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        // alignSelf: 'center',
        // alignContent: 'center',
        // marginBottom: 8
        // width: '100%',
        // height: '100%'
      }}
    >
      {/* <Text style={{ fontSize: 14, fontWeight: "400" }}>{address}</Text> */}

      {/* {coordinates ? (
        <MapPreview
          style={styles.mapPreview}
          location={coordinates}
          onPress={pickOnMapHandler}
          zoom={zoom}
        ></MapPreview>
      ) : null} */}

      <Dropdown
        mode="modal"
        style={[styles.dropdown, isFocus && { borderColor: 'blue' }]}
        placeholderStyle={{ fontSize: ScreenSize().ratio(82), fontWeight: '700', color: '#484848' }}
        // placeholderStyle={styles.placeholderStyle}
        selectedTextStyle={{ fontSize: ScreenSize().ratio(82), fontWeight: '700', color: '#484848' }}
        // selectedTextStyle={styles.selectedTextStyle}
        inputSearchStyle={styles.inputSearchStyle}
        iconStyle={styles.iconStyle}
        data={countriesList}
        search
        maxHeight={300}
        labelField="name"
        valueField="id"
        placeholder={!isFocus ? 'Select country' : '...'}
        searchPlaceholder="Search..."
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        value={country}
        onChange={item => {
          setCountry(item);
          setCity(null)
          setIsFocus(false);
          GetState(item.id).then((result) => {
            setStateList(result);
          })
        }} />

      <Dropdown
        mode='auto'
        // mode='modal'
        style={[styles.dropdown, isFocus && { borderColor: 'blue' }]}
        placeholderStyle={{ fontSize: ScreenSize().ratio(82), fontWeight: '700', color: '#484848' }}
        // placeholderStyle={styles.placeholderStyle}
        selectedTextStyle={{ fontSize: ScreenSize().ratio(82), fontWeight: '700', color: '#484848' }}
        // selectedTextStyle={styles.selectedTextStyle}
        inputSearchStyle={styles.inputSearchStyle}
        iconStyle={styles.iconStyle}
        data={stateList}
        search
        maxHeight={300}
        labelField="name"
        valueField="id"
        placeholder={!isFocus ? 'Select state' : '...'}
        searchPlaceholder="Search..."
        value={state}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={item => {
          setstate(item);
          setIsFocus(false);
          setAddress(item.name);
          setZoom(7);
          props.onAddressPicked(item.name);
          setCoordinates({
            lat: State.getStateByCodeAndCountry(item.state_code, country.iso2).latitude,
            lng: State.getStateByCodeAndCountry(item.state_code, country.iso2).longitude,
          });
          props.onLocationPicked({
            lat: State.getStateByCodeAndCountry(item.state_code, country.iso2).latitude,
            lng: State.getStateByCodeAndCountry(item.state_code, country.iso2).longitude,
          });
          GetCity(country.id, item.id).then((result) => {
            setCityList(result);
          });
        }}
      />

      <Dropdown
        mode='modal'
        style={[styles.dropdown, isFocus && { borderColor: "blue" }]}
        placeholderStyle={{ fontSize: ScreenSize().ratio(82), fontWeight: '700', color: '#484848' }}
        // placeholderStyle={styles.placeholderStyle}
        selectedTextStyle={{ fontSize: ScreenSize().ratio(82), fontWeight: '700', color: '#484848' }}
        // selectedTextStyle={styles.selectedTextStyle}
        inputSearchStyle={styles.inputSearchStyle}
        iconStyle={styles.iconStyle}
        data={cityList}
        search
        maxHeight={250}
        labelField="name"
        valueField="id"
        placeholder={!isFocus ? "Select city" : "..."}
        searchPlaceholder="Search..."
        value={city}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={(item) => {
          setCity(item);
          setCoordinates({ lat: item.latitude, lng: item.longitude });
          setAddress(`${state.name}, ${item.name}`);
          setZoom(12);
          setIsFocus(false);
          props.onLocationPicked({
            lat: item.latitude,
            lng: item.longitude,
          });
          props.onAddressPicked(`${state.name}, ${item.name}`);
        }}
      />

      {state ? (
        <TouchableOpacity
          style={{ alignItems: "center", justifyContent: "center" }}
          onPress={() => {
            setCoordinates(null);
            setAddress(null);
            setCity(null)
            setCountry(egypt)
            setstate(null)
            props.onLocationPicked(null);
            props.onAddressPicked(null)
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              // marginHorizontal: 20,
              // margin: 8,
            }}
          >
            <MaterialCommunityIcons
              name="close-circle"
              size={24}
              color="#52575D"
            />
          </View>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export default CityDropDown;

const styles = StyleSheet.create({
  // container: {
  //   padding: 20,
  //   justifyContent: "center",
  //   alignContent: "center",
  //   // backgroundColor: 'red',
  //   // flex: 1,
  //   // backgroundColor: "grey",
  //   // alignItems: 'center',
  //   // width:300
  // },
  dropdown: {
    borderColor: "gray",
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 30,
    width: '100%',
    marginVertical: 5
    // marginBottom: 10,
    // backgroundColor: 'red',
    // width: "98%",
    // flex: 1
  },
  icon: {
    marginRight: 5,
  },
  label: {
    position: "absolute",
    backgroundColor: "white",
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 14,
  },
  placeholderStyle: {
    fontSize: 12,
  },
  selectedTextStyle: {
    fontSize: 13,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  mapPreview: {
    // marginBottom: 2,
    marginTop: 6,
    marginBottom: 7,
    width: "100%",
    height: 150,
    borderColor: "#ccc",
    borderWidth: 1,
  },
});
