import { View, Text, ActivityIndicator, TouchableOpacity } from 'react-native'
import React, { useEffect, useState } from 'react'
import firebase from "../firebase/firebaseConfig";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ScreenSize from '../components/ScreenSize';
import { Input, Switch, Button, VStack, FormControl, Popover } from "native-base";
import { Radio, notification } from 'antd'
import { Image } from 'react-native';
import openNotificationWithIcon from '../components/openNotificationWithIcon'
const SettingsScreen = (props) => {
      // const [api, contextHolder] = notification.useNotification();
      const screenSize = ScreenSize()
      const [loadingOnlineData, setLoadingOnlineData] = useState(false)
      const [user, setUser] = useState(null)
      const [chatSwitch, setchatSwitch] = useState(false)
      const [phoneNumberSwitch, setphoneNumberSwitch] = useState(false)
      const [languageRadio, setlanguageRadio] = useState(null)
      const [show, setShow] = useState(false)
      const [show2, setShow2] = useState(false)
      const [show3, setShow3] = useState(false)
      const [formData, setFormData] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
      const [errors, setErrors] = useState({});
      const [isOpen, setIsOpen] = useState(false);
      // const [password, setPassword] = useState('')
      // console.log(contextHolder)
      useEffect(() => {
            setLoadingOnlineData(true)
            onAuthStateChanged(getAuth(), (data) => {
                  if (data) {
                        firebase.firestore()
                              .collection('Users')
                              .doc(data.uid)
                              .get()
                              .then(snapshot => {
                                    if (snapshot.exists) {
                                          setUser(snapshot.data())
                                          setchatSwitch(snapshot.data().contactMethods?.chat)
                                          setphoneNumberSwitch(snapshot.data().contactMethods?.phoneNumber)
                                          setlanguageRadio(snapshot.data().language)
                                          // setPassword(snapshot.data().password)
                                    }
                                    // else { alert('Does not exist')}
                              })
                  }
                  setUser(null)
            });
            setLoadingOnlineData(false)
      }, [firebase.auth()?.currentUser]);



      // notification.config({
      //       placement: 'bottomRight',
      //       bottom: 50,
      //       duration: 3,
      //       rtl: true,
      // })

      // const openNotificationWithIcon = (type, message, description) => {
      //       notification.success({
      //             message: message,
      //             description: description,
      //             duration: 0,
      //       })

      //       notification.open({
      //             message: message,
      //             description: description,
      //             duration: 0,
      //       })

      //       api[type]({
      //             message: message,
      //             description: description,
      //             duration: 0,
      //       });

      //       api.success({
      //             message: message,
      //             description: description,
      //             duration: 0,
      //       })

      //       // if (type === 'error') {
      //       //       api[type]({
      //       //             message: message,
      //       //             description: description,
      //       //             duration: 0
      //       //       });
      //       // }

      //       // if (type === 'success') {
      //       //       api[type]({
      //       //             message: message,
      //       //             description: description,
      //       //             duration: 0
      //       //       });
      //       // }
      // };


      const validate = () => {
            let inputError = {
                  // currentPassword: "",
                  // newPassword: "",
                  // confirmPassword: "",
            };

            if (!formData.currentPassword && !formData.newPassword) {
                  setErrors({
                        ...inputError,
                        currentPassword: "Enter Your Current Password",
                        newPassword: "Password should not be empty",
                  });
                  return
            }


            if (!formData.newPassword) {
                  // console.log('formData?.newPassword === undefined')
                  setErrors({
                        ...inputError,
                        newPassword: 'Password is required'
                  });
                  return
            }

            if (formData.newPassword?.length < 5) {
                  // console.log('formData.newPassword.length < 5')
                  setErrors({
                        ...inputError,
                        newPassword: 'Password is too short'
                  });
                  return
            }

            if (formData.confirmPassword !== formData.newPassword) {
                  // console.log('formData.confirmPassword !== formData.newPassword')
                  setErrors({
                        ...inputError,
                        confirmPassword: 'Password does not match'
                  });
                  return
            }

            // if (formData.currentPassword != password) {
            //       console.log('formData.currentPassword != password')
            //       setErrors({
            //             ...inputError,
            //             currentPassword: 'Current password not correct'
            //       });
            //       return
            // }

            setErrors({});
            updatePassword()
      };


      // const updatePassword = () => {
      //       const emailCred = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, formData.currentPassword)

      //       firebase.auth().currentUser.reauthenticateWithCredential(emailCred)
      //             // firebase.auth().currentUser.reauthenticateWithCredential(emailCred)
      //             .then(() => {
      //                   // return
      //                   firebase.auth().currentUser.updatePassword(formData.newPassword)
      //                   // firebase.firestore().collection('Users').doc(user.uid).update({ password: formData.newPassword })
      //             })
      //             .then(openNotificationWithIcon('success', 'Success', 'Password is successfully updated'))
      //             .catch(error => { openNotificationWithIcon('error', 'Updating Password Failed', error) })
      // }


      //working///////////////////////
      // const updatePassword = () => {
      //     const emailCred = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, formData.currentPassword)

      //     firebase.auth().currentUser.reauthenticateWithCredential(emailCred)
      //         .then(() => {
      //             // return () => {
      //             return firebase.auth().currentUser.updatePassword(formData.newPassword)
      //             // firebase.firestore().collection('Users').doc(user.uid).update({ password: formData.newPassword })
      //             // };
      //         })

      //         .catch(error => {
      //             // console.log('updated successfully')
      //             // openNotificationWithIcon('success')
      //             setErrors({ ...error, firebaaseError: error })
      //             openNotificationWithIcon('error')
      //             console.log(error)
      //         })
      // }
      //working///////////////////////


      const updatePassword = () => {
            // console.log(formData.currentPassword)
            // console.log(formData.newPassword)

            const emailCred = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, formData.currentPassword)

            firebase.auth().currentUser.reauthenticateWithCredential(emailCred)
                  .then(() => { firebase.auth().currentUser.updatePassword(formData.newPassword) })
                  .then(() => {
                        firebase.firestore().collection('Users').doc(user.uid).update({ password: formData.newPassword })
                        // openNotificationWithIcon('success', 'Success', 'Password is successfully updated')
                        notification.success({
                              message: 'Success',
                              description: 'Password is successfully updated',
                              duration: 0
                        })
                  })
                  .then(() => {
                        props.navigation.navigate('Home')
                        // const newForm = { currentPassword: '', newPassword: '', confirmPassword: '' }
                        // setFormData(newForm)
                        // usestate not working
                  })
                  .catch(error => {
                        notification.error({
                              message: 'Updating Password Failed',
                              description: error.message,
                              duration: 0
                        })
                  })
            // .catch(error => { openNotificationWithIcon('error', 'Updating Password Failed', error.message) })
      }



      if (user) {
            return (
                  <View style={{ flex: 1, marginHorizontal: screenSize.isWide ? '25%' : 10, paddingBottom: screenSize.ratio(70), }}>
                        {/* {contextHolder} */}

                        {/* <Button
                              colorScheme="danger"
                              onPress={() => {
                                    props.navigation.navigate('HomeScreen')

                                    openNotificationWithIcon('success', 'Sent Successfully', 'A password reset email has been sent to your email')

                                    notification.error({
                                          message: 'Updating Password Failed',
                                          description: 'error',
                                          duration: 0
                                    })

                                    // notification.success({
                                    //       message: 'Success',
                                    //       description: 'Password is successfully updated',
                                    //       duration: 0
                                    // })

                                    // api['success']({
                                    //       message: 'Sent Successfully',
                                    //       description: 'A password reset email has been sent to your email',
                                    //       duration: 6,
                                    //       role: 'status',
                                    //       type: 'success',
                                    //       // btn
                                    // })
                              }}>Send</Button> */}

                        {loadingOnlineData ? (
                              <View>
                                    <Text
                                          style={{
                                                fontSize: 20,
                                                fontWeight: "bold",
                                                textAlign: "center",
                                          }}
                                    >
                                          Loading Online Data...
                                    </Text>
                                    <ActivityIndicator size={"large"} />
                              </View>
                        ) : null}

                        <View style={{ backgroundColor: '#e5e5e5', borderRadius: 12, marginTop: 10, justifyContent: 'center', paddingHorizontal: 15, paddingTop: 7.5, paddingBottom: 13 }}>
                              <Text style={{ fontSize: screenSize.ratio(50), fontWeight: '900', color: '#3b3b3b' }}>Contact Method :</Text>

                              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 7 }}>
                                    <Text style={{ fontSize: screenSize.ratio(60), fontWeight: '700', color: '#5b5b5b' }}>Chat</Text>
                                    <Switch
                                          size={screenSize.ratio(50)}
                                          disabled={!phoneNumberSwitch}
                                          value={chatSwitch}
                                          offTrackColor="orange.100" onTrackColor="orange.200" onThumbColor="orange.500" offThumbColor="orange.50"
                                          onToggle={() => {
                                                setchatSwitch(!chatSwitch);
                                                firebase.firestore().collection('Users').doc(user.uid).update('contactMethods.chat', !chatSwitch)
                                                // firebase.firestore().collection('Users').doc(user.uid).update({contactMethods['phoneNumber'] : prev})
                                          }}
                                    />
                              </View>

                              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 2 }}>
                                    <Text style={{ fontSize: screenSize.ratio(60), fontWeight: '700', color: '#5b5b5b' }}>Phone Number</Text>
                                    <Switch
                                          size={screenSize.ratio(50)}
                                          disabled={!chatSwitch}
                                          value={phoneNumberSwitch}
                                          offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50"
                                          // onToggle={() => { setphoneNumberSwitch(prevstate => !prevstate) }}
                                          onToggle={() => {
                                                setphoneNumberSwitch(!phoneNumberSwitch);
                                                firebase.firestore().collection('Users').doc(user.uid).update('contactMethods.phoneNumber', !phoneNumberSwitch)
                                          }}
                                    />
                              </View>
                        </View>



                        <View style={{ backgroundColor: '#e5e5e5', borderRadius: 12, marginTop: 35, justifyContent: 'center', paddingHorizontal: 15, paddingTop: 7.5, paddingBottom: 13, marginTop: 10 }}>
                              <Text style={{ fontSize: screenSize.ratio(50), fontWeight: '900', color: '#3b3b3b' }}>Language :</Text>
                              <Radio.Group size='middle'
                                    style={{ marginTop: 7, width: '100%' }}
                                    buttonStyle="solid"
                                    onChange={newValue => { setlanguageRadio(newValue.target.value); firebase.firestore().collection('Users').doc(user.uid).update({ language: newValue.target.value }) }}
                                    // onChange={newValue => { console.log(newValue.target.value); setlanguageRadio(newValue.target.value) }}
                                    value={languageRadio}
                              >
                                    <Radio.Button
                                          style={{ width: '50%', textAlign: 'center' }}
                                          value="english">English</Radio.Button>
                                    <Radio.Button
                                          style={{ width: '50%', textAlign: 'center' }}
                                          value="arabic">اللغة العربية</Radio.Button>
                              </Radio.Group>
                        </View>



                        <View style={{ backgroundColor: '#e5e5e5', borderRadius: 12, marginTop: 10, justifyContent: 'center', paddingHorizontal: 15, paddingTop: 7.5, paddingBottom: 13 }}>
                              <Text style={{ fontSize: screenSize.ratio(50), fontWeight: '900', color: '#3b3b3b' }}>Change Password :</Text>
                              <VStack style={{ marginTop: 7 }}>
                                    <FormControl isRequired isInvalid={'currentPassword' in errors}>
                                          <FormControl.Label _text={{ fontWeight: '700', fontSize: screenSize.ratio(60), color: '#5b5b5b' }}>
                                                Current Password
                                          </FormControl.Label>
                                          <Input
                                                type={show ? "text" : "password"}
                                                InputRightElement={
                                                      <Button
                                                            size="xs"
                                                            rounded="none"
                                                            w="1/6"
                                                            h="full"
                                                            onPress={() => setShow(!show)}
                                                      >
                                                            {show ? "Hide" : "Show"}
                                                      </Button>}
                                                // placeholder="Current Password"
                                                onChangeText={value => setFormData({ ...formData, currentPassword: value })}
                                          />
                                          {'currentPassword' in errors ? <FormControl.ErrorMessage>{errors.currentPassword}</FormControl.ErrorMessage> :
                                                null
                                                // <FormControl.HelperText>
                                                //       Name should contain atleast 3 character.
                                                // </FormControl.HelperText>
                                          }
                                    </FormControl>

                                    <FormControl isRequired isInvalid={'newPassword' in errors}>
                                          <FormControl.Label _text={{ fontWeight: '700', fontSize: screenSize.ratio(60), color: '#5b5b5b' }}>
                                                New Password
                                          </FormControl.Label>
                                          <Input
                                                type={show2 ? "text" : "password"}
                                                InputRightElement={
                                                      <Button
                                                            size="xs"
                                                            rounded="none"
                                                            w="1/6"
                                                            h="full"
                                                            onPress={() => setShow2(!show2)}
                                                      >
                                                            {show2 ? "Hide" : "Show"}
                                                      </Button>}
                                                // placeholder="Current Password"
                                                onChangeText={value => setFormData({ ...formData, newPassword: value })}
                                          />
                                          {'newPassword' in errors ? <FormControl.ErrorMessage>{errors.newPassword}</FormControl.ErrorMessage> : null}
                                    </FormControl>


                                    <FormControl isRequired isInvalid={'confirmPassword' in errors}>
                                          <FormControl.Label _text={{ fontWeight: '700', fontSize: screenSize.ratio(60), color: '#5b5b5b' }}>
                                                Confirm New Password
                                          </FormControl.Label>
                                          <Input
                                                type={show3 ? "text" : "password"}
                                                InputRightElement={
                                                      <Button
                                                            size="xs"
                                                            rounded="none"
                                                            w="1/6"
                                                            h="full"
                                                            onPress={() => setShow3(!show3)}
                                                      >
                                                            {show3 ? "Hide" : "Show"}
                                                      </Button>}
                                                // placeholder="Current Password"
                                                onChangeText={value => setFormData({ ...formData, confirmPassword: value })}
                                          />
                                          {'confirmPassword' in errors ? <FormControl.ErrorMessage>{errors.confirmPassword}</FormControl.ErrorMessage> : null}
                                    </FormControl>

                                    <Button onPress={validate} mt="5" colorScheme="cyan">
                                          Submit
                                    </Button>
                              </VStack>
                        </View>

                        {/* <TouchableOpacity
                              style={{ alignSelf: 'flex-end' }}
                              onPress={() => {
                                    firebase.auth().sendPasswordResetEmail(user.email)
                                          .then(openNotificationWithIcon('success', 'Sent Successfully', 'A password reset email has been sent to your email'))
                              }
                              }>
                              <Text style={{ color: '#005dfe', fontSize: screenSize.ratio(75), fontWeight: '500' }}>
                                    Forgot Your Password ?
                              </Text>
                        </TouchableOpacity> */}

                        <Popover
                              isOpen={isOpen}
                              onClose={() => setIsOpen(!isOpen)}
                              // placement='top'
                              trigger={triggerProps => {
                                    return <TouchableOpacity
                                          {...triggerProps}
                                          onPress={() => setIsOpen(true)}
                                          style={{ alignSelf: 'flex-end', marginTop: 4, marginBottom: 15 }}
                                    >
                                          <Text style={{ color: '#005dfe', fontSize: screenSize.ratio(75), fontWeight: '500' }}>
                                                Forgot Your Password ?
                                          </Text>
                                    </TouchableOpacity>

                              }}>
                              <Popover.Content accessibilityLabel="Delete Customerd" w="56">
                                    <Popover.Arrow />
                                    <Popover.CloseButton />
                                    <Popover.Header>Send Reset Password Email ?</Popover.Header>
                                    <Popover.Body>
                                          Are you sure that you want to reset your password by sending a reset password email to your email address ?
                                    </Popover.Body>
                                    <Popover.Footer justifyContent="flex-end">
                                          <Button.Group space={2}>
                                                <Button
                                                      onPress={() => setIsOpen(false)}
                                                      colorScheme="coolGray" variant="ghost">
                                                      Cancel
                                                </Button>
                                                <Button
                                                      onPress={() => {
                                                            setIsOpen(false)
                                                            firebase.auth().sendPasswordResetEmail(user.email)
                                                                  .then(notification.success({
                                                                        message: 'Sent Successfully',
                                                                        description: 'A password reset email has been sent to your email',
                                                                        duration: 0
                                                                  }))
                                                            // .then(openNotificationWithIcon('success', 'Sent Successfully', 'A password reset email has been sent to your email'))
                                                      }
                                                      }
                                                      colorScheme="danger">Send</Button>
                                          </Button.Group>
                                    </Popover.Footer>
                              </Popover.Content>
                        </Popover>
                  </View>
            )
      }
      // else
      if (!user) {
            return (
                  <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                        <Image source={require('../assets/noData.jpg')} style={{
                              height: screenSize.ratio(4), width: screenSize.ratio(2.1), zIndex: 1, alignSelf: 'center', resizeMode: 'contain'
                              // borderRadius: 30
                        }} />
                        <Text style={{ fontSize: screenSize.ratio(40), fontWeight: '700', fontFamily: 'Avigea' }}>You Have To Login First</Text>
                        <Button rounded='xs' width={screenSize.ratio(3.5)} marginTop={50} onPress={() => props.navigation.navigate('MyAccount')}>Login</Button>
                  </View>
            )
      }
}

export default SettingsScreen


// const currentPass = window.prompt('Please enter current password');
// const emailCred = firebase.auth.EmailAuthProvider.credential(
//       firebase.auth().currentUser, currentPass);
// firebase.auth().currentUser.reauthenticateWithCredential(emailCred)
//       .then(() => {
//             // User successfully reauthenticated.
//             const newPass = window.prompt('Please enter new password');
//             return firebase.auth().currentUser.updatePassword(newPass);
//       })
//       .catch(error => {
//             // Handle error.
//       });
