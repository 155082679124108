import React from 'react';
import { View, StyleSheet, TextInput, TouchableOpacity, Image, Text } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { COLOURS } from '../components/database/Database';

export default function AppTextInput({
  leftIcon,
  width = '100%',
  rightIcon,
  handlePasswordVisibility,
  ...otherProps
}) {
  // console.log(JSON.stringify(otherProps))
  return (
    <View style={[styles.container, { width }]}>
      {leftIcon && (
        <MaterialCommunityIcons
          name={leftIcon}
          size={20}
          color={COLOURS.mediumGrey}
          style={styles.icon}
        />
      )}

      {otherProps.placeholder === 'Phone Number' ?
        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          // backgroundColor: 'blue'
        }}>

          <TextInput
            style={styles.phoneNumberinput}
            placeholderTextColor={COLOURS.mediumGrey}
            {...otherProps}
          />

          <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 4,
            // backgroundColor: 'red',
          }}>
            <Image style={{
              height: 16, width: 22, opacity: 0.7, marginRight: 3, top: 1
              // backgroundColor: 'blue'
            }} source={{ uri: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSowxTH-tRTPOTl5LvQIJEpSZ-73369EwehJfMzNc0Isw&s' }} />
            <Text style={{
              fontSize: 18, opacity: 0.7, height: '100%',
              // backgroundColor: 'green'
            }}>(+2)</Text>
          </View>

        </View>
        :
        <TextInput
          style={styles.input}
          placeholderTextColor={COLOURS.mediumGrey}
          {...otherProps}
        />
      }

      {/* <TextInput
        style={styles.input}
        placeholderTextColor={COLOURS.mediumGrey}
        {...otherProps}
      /> */}
      {rightIcon && (
        <TouchableOpacity onPress={handlePasswordVisibility}>
          <MaterialCommunityIcons
            name={rightIcon}
            size={20}
            color={COLOURS.mediumGrey}
            style={styles.rightIconStyles}
          />
        </TouchableOpacity>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#e8eaed",
    borderRadius: 20,
    flexDirection: 'row',
    padding: 10,
    marginVertical: 5,
    // width: '100%'
  },
  icon: {
    marginRight: 10
  },
  input: {
    // flex: 1,
    width: '100%',
    fontSize: 20,
    color: "#000",
    opacity: 0.7
  },
  phoneNumberinput: {
    // flex: 1,
    // width: '100%',
    fontSize: 20,
    color: "#000",
    opacity: 0.7,
    // backgroundColor: 'red'
  },
  rightIconStyles: {
    alignSelf: 'center',
    marginLeft: 10
  }
});
