import {
    PhoneAuthProvider,
    RecaptchaVerifier,
    signInWithCredential,
    signInWithPhoneNumber,
    getAuth
} from "firebase/auth";
import { useCallback, useEffect, useRef, useState } from "react";
// import { auth } from '../firebase/firebaseConfig'
import openNotificationWithIcon from "../components/openNotificationWithIcon";
import { Image, Text, View } from "react-native";
import { Button, Input, VStack } from "native-base";
import ScreenSize from "../components/ScreenSize";
import { OtpInput } from "react-native-otp-entry";
// import secon from '../Screens/EditProfile'
// import { Feather, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import firebase, { auth } from '../firebase/firebaseConfig'
// import { getAuth, GoogleAuthProvider, signInWithCredential, onAuthStateChanged } from 'firebase/auth';
import {
    setDoc,
    doc,
} from "firebase/firestore";
import { TextInput } from "react-native";
import { StyleSheet } from "react-native";
const SigninWithPhone = ({ navigation }) => {
    const [otp, setOtp] = useState("");
    const [verificationId, setVerificationId] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otpDisabled, setOtpDisabled] = useState(true)
    // const [resendCountdown, setResendCountdown] = useState('')
    const screenSize = ScreenSize();
    const otpRef = useRef()

    const [timer, setTimer] = useState(0);
    const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

    useEffect(() => {
        timer > 0 && setTimeout(timeOutCallback, 1000);
    }, [timer, timeOutCallback]);

    // console.log(timer);

    const resetTimer = function () {
        if (!timer) {
            setTimer(60);
        }
    };

    // useEffect(() => {
    //     let timer = 60;
    //     if (resendCountdown > 0) {
    //         timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000)
    //     }
    //     return () => clearTimeout(timer)
    // }, [resendCountdown])

    const setUpRecaptcha = () => {
        // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        window.recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container",
            // 'sign-in-button',
            {
                size: "invisible",
                // 'callback': function (response) {
                //     console.log(response)
                //     // reCAPTCHA solved, allow signInWithPhoneNumber.
                //     // onSignInSubmit();
                // }
            },
            auth
        );
    };

    const onSendOtp = async () => {
        // Ensure that the phone number is valid before sending OTP
        if (!phoneNumber) {
            console.error("Invalid phone number");
            openNotificationWithIcon('error', 'Error', "Invalid phone number")
            return;
        }

        if (!window.recaptchaVerifier) {
            setUpRecaptcha();
        }

        try {
            const result = await signInWithPhoneNumber(
                auth,
                `+2${phoneNumber}`,
                window.recaptchaVerifier
            );
            setVerificationId(result.verificationId);
            setOtpDisabled(false)
            openNotificationWithIcon('success', 'OTP sent successfully')
            resetTimer()
            // openNotificationWithIcon('success', 'Success', 'OTP sent successfully')
            // otpRef.current.focus()

        } catch (error) {
            console.error("Error sending OTP:", error);
            openNotificationWithIcon('error', 'Error sending OTP', error.message)
        }
    };

    const onVerify = async (vOtp) => {
        if (!verificationId || !vOtp) {
            console.error("Invalid verification ID or OTP");
            openNotificationWithIcon('error', 'Error', 'Invalid verification ID or OTP')
            return;
        }

        try {
            const credential = PhoneAuthProvider.credential(verificationId, vOtp);
            await signInWithCredential(auth, credential);
            openNotificationWithIcon('success', 'Successfully signed in with phone number')
            // openNotificationWithIcon('success', 'Success', 'Successfully signed in with Phone Number')

            if (firebase.auth()?.currentUser?.metadata?.creationTime === firebase.auth()?.currentUser?.metadata?.lastSignInTime) {

                firebase
                    .firestore()
                    .collection('Users')
                    .doc(getAuth()?.currentUser.uid)
                    // .doc(firebase.auth().currentUser.uid ? firebase.auth().currentUser.uid : getAuth().currentUser.uid)
                    .set({
                        displayName: phoneNumber,
                        email: '',
                        // email: googleUserData.user.providerData[0].email === null ? googleUserData.user.email : googleUserData.user.providerData[0].email,
                        uid: firebase.auth()?.currentUser?.uid,
                        photoURL: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector-PNG-Clipart.png',
                        phoneNumber: phoneNumber,
                        creationTime: firebase.firestore.FieldValue.serverTimestamp(),
                        lastSignInTime: firebase.firestore.FieldValue.serverTimestamp(),
                        provider: 'phoneNumber',
                        language: 'english'
                    })
                // setDoc(doc(firebase.firestore(), "userChats", googleUserData.user.uid))
                setDoc(doc(firebase.firestore(), "userChats", firebase.auth()?.currentUser?.uid), {})
                // setDoc(doc(firebase.firestore(), "userChats", data.uid), {})

                navigation.navigate('EditProfile', { userID: firebase.auth()?.currentUser?.uid })
            }

            if (firebase.auth()?.currentUser?.metadata?.creationTime != firebase.auth()?.currentUser?.metadata?.lastSignInTime) {
                firebase
                    .firestore()
                    .collection('Users')
                    .doc(getAuth()?.currentUser?.uid)
                    .update({
                        // name: data.displayName,
                        // email: data.email,
                        // uid: data.uid,
                        // photo: data.photoURL,
                        // phoneNumber: data.phoneNumber,
                        // creationTime: data.metadata.creationTime,
                        lastSignInTime: firebase.firestore.FieldValue.serverTimestamp(),
                        // lastSignInTime: googleUserData.user.metadata.lastSignInTime,
                    })
                firebase
                    .firestore()
                    .collection('activityLog')
                    .doc(getAuth()?.currentUser?.uid)
                    .collection('activityLog')
                    .add({
                        signIn: firebase.firestore.FieldValue.serverTimestamp(),
                        // lastSignInTime: googleUserData.user.metadata.lastSignInTime,
                    })
            }
        } catch (error) {
            console.error("Error signing in with OTP:", error);
            openNotificationWithIcon('error', 'Error signing in with OTP', error.message)
        }
    };


    useEffect(() => {
        setUpRecaptcha();
    }, []);


    return (
        <View style={{
            justifyContent: 'center',
            alignItems: 'center'
            // width: '30%'
            // flex: 1,
            // backgroundColor: 'red',
        }} className="otpVerification">

            {otpDisabled &&
                <View style={{
                    backgroundColor: '#d4d4d4',
                    borderRadius: 12,
                    justifyContent: 'center',
                    padding: 10,
                    paddingHorizontal: 15,
                    paddingTop: 7.5,
                    paddingBottom: 13,
                    alignSelf: 'center',
                    marginTop: '5%',
                    width: '90%',
                }}>
                    <Text style={{ fontSize: screenSize.ratio(70), fontWeight: '900', color: '#3b3b3b' }}>Phone Number :</Text>
                    <VStack style={{
                        marginTop: 10,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        // width: '100%',
                        // flex:1
                        // backgroundColor: 'red',
                        // overflow:'hidden'
                    }}>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 15,
                            bottom: 2.5
                            // alignSelf: 'center'
                            // backgroundColor: 'blue',
                        }}>
                            <Image
                                style={{ height: 16, width: 22, opacity: 0.7, marginRight: 3, top: 1.5 }}
                                source={{ uri: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSowxTH-tRTPOTl5LvQIJEpSZ-73369EwehJfMzNc0Isw&s' }}
                            />
                            <Text style={{
                                fontSize: 18,
                                opacity: 0.7,
                                // marginTop: -3
                            }}>(+2)</Text>
                        </View>

                        <TextInput
                            placeholderTextColor={'grey'}
                            // clearButtonMode="always"
                            // autoCapitalize="none"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="01312973660"
                            style={{
                                fontSize: screenSize.ratio(65),
                                borderWidth: 2,
                                borderRadius: 5,
                                borderColor: '#999999',
                                // borderColor: '#5C6372',
                                paddingVertical: 1,
                                paddingHorizontal: 5,
                                width: '100%',
                                // alignItems: 'center',
                                // alignSelf: 'center',
                                // justifyContent: 'center',
                                // backgroundColor: 'red',
                                // shadowOffset: 50,
                                // shadowOpacity: 30,
                                // elevation: 30,

                                // screenSize.isWide
                                // ? screenSize.width / 50
                                // : screenSize.width / 19,
                                // paddingLeft: 10,
                                // marginTop: 6,
                                // marginHorizontal: 10
                            }}
                        // placeholder="Search"
                        // value={searchQuery}
                        // onChangeText={(e) => setPhoneNumber(e.target.value)} 
                        />

                        {/* <Input
                            // size='lg'
                            type="text"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="01312973660"
                            style={{
                                // backgroundColor: 'red',
                                // width: '1000%',

                            }}
                        /> */}

                    </VStack>

                    {/* <View style={styles.Form}>
                        <View style={styles.Top}>
                            <TextInput
                                keyboardType="email-address"
                                placeholder="Email"
                                style={styles.Input}
                            />
                        </View>

                        <View style={styles.Bottom}>
                            <TextInput
                                placeholder="Password"
                                secureTextEntry="true"
                                style={styles.Input}
                            />
                            <View style={styles.ButtonContainer}>
                                <Button style={styles.Button} iconLeft title="Login" />

                                <Button style={styles.Button} iconLeft title="Signup" />
                            </View>
                        </View>
                    </View> */}

                    <Button disabled={timer > 0} style={{ height: 30 }} onPress={() => { onSendOtp(); }} mt="2" colorScheme="cyan">
                        {timer > 0
                            ? `Resend OTP in ${timer}`
                            : "Send OTP"
                        }
                    </Button>
                </View>
            }



            {/* <Button onPress={() => navigation.navigate('EditProfile')}>
                editscreen
            </Button> */}

            {/* <View style={{ flexDirection: 'row', marginTop: 9, justifyContent: 'center', alignItems: 'center' }}> */}
            {/* <Button
                    // disabled={otpDisabled}
                    disabled={otpDisabled}
                    onPress={onVerify}>Verify
                </Button> */}

            {/* <View style={{
                    width: 70,
                    // height: 30,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'red',
                    // flex: 1
                }}> */}

            {!otpDisabled &&
                <View style={{
                    flexDirection: 'row', marginTop: 40, justifyContent: 'center', alignItems: 'center', marginHorizontal: 10,
                    backgroundColor: '#d4d4d4',
                    borderRadius: 12,
                    padding: 10,
                    // paddingHorizontal: 15,
                    // paddingTop: 7.5,
                    // paddingBottom: 13,
                    // justifyContent: 'center',
                    // alignSelf: 'center',
                    // marginTop: '10%',
                    // width: '90%',
                }}>
                    {/* <Image style={{ height: 25, width: 25, marginRight: 7 }} source={{ uri: 'https://png.pngtree.com/png-vector/20190724/ourmid/pngtree-true-sign-png-png-image_1589429.jpg' }}>
                    </Image> */}

                    <OtpInput
                        ref={otpRef}
                        autoFocus={true}
                        onFilled={(vOtp) => onVerify(vOtp)}
                        // onFilled={(t) => console.log(t)}
                        // onFilled={console.log(otp)}
                        textInputProps={{ accessibilityLabel: "One-Time Password", }}
                        disabled={otpDisabled}
                        numberOfDigits={6}
                        onTextChange={e => (
                            setOtp(e)
                            // ,console.log(e)
                        )}
                        focusColor="green"
                        focusStickBlinkingDuration={500}
                        type="numeric"
                        theme={{
                            containerStyle: { width: screenSize.ratio(4), alignSelf: 'center', },
                            pinCodeContainerStyle: { width: screenSize.ratio(30), height: screenSize.ratio(20), borderColor: '#fff' },
                            // pinCodeTextStyle: styles.pinCodeText,
                            // focusStickStyle: styles.focusStick,
                            // focusedPinCodeContainerStyle: styles.activePinCodeContainer,
                        }}

                    // onFilled={(text) => console.log(`OTP is ${text}`)}
                    />
                </View >
            }




            {/* </View> */}

            {/* <OtpInput numberOfDigits={6} onTextChange={(text) => console.log(text)} /> */}
            {/* <Input
                    disabled={otpDisabled}
                    type="number"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    maxLength={6}
                    placeholder="OTP"
                /> */}

            {/* </View> */}

            {/* <input
                type="number"
                value={otp}
                onChange={(e) => (
                    setOtp(e.target.value),
                    console.log(e.target.value)
                )}
                maxLength={6}
                placeholder="OTP"
            /> */}
            {/* <button onClick={onSendOtp}>Send OTP</button> */}
            {/* <button onClick={onVerify}>Verify</button> */}

            <div id="recaptcha-container"></div>
        </View>
    );
    // return (
    //     <div className="otpVerification">
    //         <input
    //             type="number"
    //             value={otp}
    //             onChange={(e) => setOtp(e.target.value)}
    //             maxLength={6}
    //             placeholder="OTP"
    //         />
    //         <div id="recaptcha-container"></div>
    //         <button onClick={onSendOtp}>Send OTP</button>
    //         <button onClick={onVerify}>Verify</button>
    //     </div>
    // );
};

export default SigninWithPhone;

const styles = StyleSheet.create({
    Bottom: {
        flex: 1,
        justifyContent: 'flex-start',
        width: '100%',
        borderWidth: 1,
        borderColor: 'red',
    },
    Top: {
        flex: 1,
        justifyContent: 'flex-end',
        width: '100%',
        borderWidth: 1,
        borderColor: 'red',
    },
    Input: {
        justifyContent: 'center',
        width: '80%',
        alignSelf: 'center',
        margin: 20,
        borderWidth: 1,
        borderColor: 'red',
    },
    Form: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        paddingRight: 50,
        paddingLeft: 50,
    },

    ButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
    },

    Button: {
        paddingVertical: 10,
        textAlign: 'center',
        padding: 5,
    },
});

// // import React, { useState, useEffect } from 'react';
// // import { Button, TextInput } from 'react-native';
// // // import auth from '@react-native-firebase/auth';
// // import { getAuth, RecaptchaVerifier } from "firebase/auth";
// // import firebase, { auth } from '../firebase/firebaseConfig'


// // // const auth = getAuth();
// // getAuth().languageCode = 'it';
// // // To apply the default browser preference instead of explicitly setting it.
// // // auth.useDeviceLanguage();

// // // window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
// // //     'size': 'invisible',
// // //     'callback': (response) => {
// // //         // reCAPTCHA solved, allow signInWithPhoneNumber.
// // //         onSignInSubmit();
// // //     }
// // // });

// // export default function PhoneSignIn() {
// //     // If null, no SMS has been sent
// //     const [confirm, setConfirm] = useState(null);

// //     // verification code (OTP - One-Time-Passcode)
// //     const [code, setCode] = useState('');

// //     // Handle login
// //     function onAuthStateChanged(user) {
// //         if (user) {
// //             console.log('Signed in with phone successfully')
// //             // Some Android devices can automatically process the verification code (OTP) message, and the user would NOT need to enter the code.
// //             // Actually, if he/she tries to enter it, he/she will get an error message because the code was already used in the background.
// //             // In this function, make sure you hide the component(s) for entering the code and/or navigate away from this screen.
// //             // It is also recommended to display a message to the user informing him/her that he/she has successfully logged in.
// //         }
// //     }

// //     useEffect(() => {
// //         // const subscriber = auth().onAuthStateChanged(onAuthStateChanged);
// //         const subscriber = getAuth().onAuthStateChanged(onAuthStateChanged);
// //         // const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
// //         return subscriber; // unsubscribe on unmount
// //     }, []);

// //     // Handle the button press
// //     async function signInWithPhoneNumber(phoneNumber) {
// //         // const confirmation = await auth().signInWithPhoneNumber(phoneNumber);
// //         const confirmation = await auth.signInWithPhoneNumber(phoneNumber);
// //         // const confirmation = await firebase.auth().signInWithPhoneNumber(phoneNumber);
// //         setConfirm(confirmation);
// //     }

// //     async function confirmCode() {
// //         try {
// //             await confirm.confirm(code);
// //         } catch (error) {
// //             console.log('Invalid code.');
// //         }
// //     }

// //     if (!confirm) {
// //         return (
// //             <Button
// //                 title="Phone Number Sign In"
// //                 onPress={() => signInWithPhoneNumber('+211111111111')}
// //             />
// //         );
// //     }

// //     return (
// //         <>
// //             <TextInput value={code} onChangeText={text => setCode(text)} />
// //             <Button title="Confirm Code" onPress={() => confirmCode()} />
// //         </>
// //     );
// // }



// import {
//     PhoneAuthProvider,
//     RecaptchaVerifier,
//     signInWithCredential,
//     signInWithPhoneNumber,
// } from "firebase/auth";
// import React, { useState, useEffect } from 'react';
// import { Button, TextInput } from 'react-native';
// // import auth from '@react-native-firebase/auth';
// import firebase, { auth } from '../firebase/firebaseConfig'

// export default function SigninWithPhone({ phoneNumber }) {
//     // If null, no SMS has been sent
//     const [confirm, setConfirm] = useState(null);

//     // verification code (OTP - One-Time-Passcode)
//     const [code, setCode] = useState('');

//     // Handle login
//     function onAuthStateChanged(user) {
//         if (user) {
//             // Some Android devices can automatically process the verification code (OTP) message, and the user would NOT need to enter the code.
//             // Actually, if he/she tries to enter it, he/she will get an error message because the code was already used in the background.
//             // In this function, make sure you hide the component(s) for entering the code and/or navigate away from this screen.
//             // It is also recommended to display a message to the user informing him/her that he/she has successfully logged in.
//         }
//     }

//     useEffect(() => {
//         const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
//         return subscriber; // unsubscribe on unmount
//     }, []);

//     // Handle the button press
//     async function signInWithPhone() {
//         console.log(phoneNumber)
//         const confirmation = await
//             // firebase.auth().
//             signInWithPhoneNumber(phoneNumber);
//         setConfirm(confirmation);
//     }

//     async function confirmCode() {
//         try {
//             await confirm.confirm(code);
//         } catch (error) {
//             console.log('Invalid code.');
//         }
//     }

//     if (!confirm) {
//         return (
//             <Button
//                 title="Phone Number Sign In"
//                 onPress={() => signInWithPhone('+1 650-555-3434')}
//             />
//         );
//     }

//     return (
//         <>
//             <TextInput value={code} onChangeText={text => setCode(text)} />
//             <Button title="Confirm Code" onPress={() => confirmCode()} />
//         </>
//     );
// }


// import React, { useState, useEffect } from 'react';
// import { Button, TextInput, Text } from 'react-native';
// import firebase, { auth } from '../firebase/firebaseConfig'
// // import auth from '@react-native-firebase/auth';

// export default function PhoneVerification() {
//     // Set an initializing state whilst Firebase connects
//     const [initializing, setInitializing] = useState(true);
//     const [user, setUser] = useState();

//     // If null, no SMS has been sent
//     const [confirm, setConfirm] = useState(null);

//     const [code, setCode] = useState('');

//     // Handle user state changes
//     function onAuthStateChanged(user) {
//         setUser(user);
//         if (initializing) setInitializing(false);
//     }

//     useEffect(() => {
//         const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
//         return subscriber; // unsubscribe on unmount
//     }, []);

//     // Handle create account button press
//     async function createAccount() {
//         try {
//             await auth.createUserWithEmailAndPassword('jane.doe@example.com', 'SuperSecretPassword!');
//             console.log('User account created & signed in!');
//         } catch (error) {
//             if (error.code === 'auth/email-already-in-use') {
//                 console.log('That email address is already in use!');
//             }

//             if (error.code === 'auth/invalid-email') {
//                 console.log('That email address is invalid!');
//             }
//             console.error(error);
//         }
//     }

//     // Handle the verify phone button press
//     const verifyPhoneNumber = async (phoneNumber) => {
//         try {
//             const confirmation = await auth.signInWithPhoneNumber(phoneNumber);
//             // const confirmation = await auth.verifyPhoneNumber(phoneNumber);
//             setConfirm(confirmation);
//         } catch (error) {
//             console.log('error sending code: ', error)
//         }
//     }

//     // // Handle the verify phone button press
//     // async function verifyPhoneNumber(phoneNumber) {
//     //     try {

//     //         const confirmation = await auth.signInWithPhoneNumber(201555531216);
//     //         // const confirmation = await auth.verifyPhoneNumber(phoneNumber);
//     //         setConfirm(confirmation);
//     //     } catch (error) {
//     //         console.log('error sending code: ', error)
//     //     }
//     // }

//     // Handle confirm code button press
//     async function confirmCode() {
//         try {
//             const credential = await confirm.confirm(code)
//             //const credential = auth.PhoneAuthProvider.credential(confirm.verificationId, code);
//             let userData = await auth.currentUser.linkWithCredential(credential);
//             setUser(userData.user);
//         } catch (error) {
//             if (error.code == 'auth/invalid-verification-code') {
//                 console.log('Invalid code.');
//             } else {
//                 console.log('Account linking error');
//             }
//         }
//     }

//     if (initializing) return null;

//     // if (!user) {
//     //     return <Button title="Login" onPress={() => createAccount()} />;
//     // } else

//     // if (!user.phoneNumber) {
//     if (!user) {
//         if (!confirm) {
//             return (
//                 <Button
//                     title="Verify Phone Number"
//                     onPress={() => verifyPhoneNumber('201555531216')}
//                 />
//             );
//         }
//         return (
//             <>
//                 <TextInput value={code} onChangeText={text => setCode(text)} />
//                 <Button title="Confirm Code" onPress={() => confirmCode()} />
//             </>
//         );
//     }
//     // else {
//     //     return (
//     //         <Text>
//     //             Welcome! {user.phoneNumber} linked with {user.email}
//     //         </Text>
//     //     );
//     // }
// }